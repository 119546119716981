import {
  Box,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import TextInput from '../input/TextInput';
import { LABEL_KHO } from '~/utils/label.constant';
import DatetimeInput from '../input/DatetimeInput';
import moment from 'moment';
import TableDisplay from '../table/TableDisplay';
import ButtonBase from '../button/ButtonBase';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useConfirmContext from '~/hooks/hookContext/useConfirmContext';
import { numeralCustom } from '~/utils/helpers';
import { MdRecycling } from 'react-icons/md';
import { IoMdTrash } from 'react-icons/io';
import { IoReturnUpBackOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import FormHD3 from '../form/hd3/FormHD3';
import useAuthorize from '~/hooks/useAuthorize';
import { FaRegCheckCircle } from 'react-icons/fa';

function ExpandHd2({ data, setLoad, hideAction }) {
  const showAlert = useAlertContext();
  const showConfirm = useConfirmContext();
  const { asyncDelete } = useApisContext();
  const allowAuthorize = useAuthorize();
  const navigate = useNavigate();
  const [openFormReturn, setOpenFormReturn] = useState(false);

  const handleDelete = async () => {
    try {
      const resp = await asyncDelete({ apiCode: 'hd2', uniqueValue: data._id });
      if (!resp?.error) {
        setLoad((prev) => prev + 1);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong',
      });
    }
  };

  return (
    <>
      <FormHD3
        open={openFormReturn}
        setLoad={setLoad}
        handleClose={() => setOpenFormReturn(false)}
        defaultValues={{ hd2: data }}
      />
      <Box
        sx={{
          padding: '20px',
          backgroundColor: 'whitish.graySoft',
          border: '1px solid',
          borderColor: 'primary.main',
          borderRadius: '0 0 4px 4px',
          position: 'relative',
          zIndex: 0,
        }}
      >
        <Grid container spacing="20px">
          <Grid item xs={12} md={5}>
            <Stack spacing="20px">
              <TextInput
                readOnly
                labelWidth="40%"
                label="Số chứng từ"
                value={data.so_ct}
              />
              <TextInput
                readOnly
                labelWidth="40%"
                label={LABEL_KHO}
                value={data.ten_kho}
              />
              <TextInput
                readOnly
                labelWidth="40%"
                label="Nhân viên"
                value={data.ten_nv || data.ma_nv}
              />
              <TextInput
                readOnly
                labelWidth="40%"
                label="Khách hàng"
                value={data.ten_kh}
              />
              <TextInput
                readOnly
                labelWidth="40%"
                label="Khách đã trả"
                value={numeralCustom(data?.exfields?.payed).format()}
              />
              <TextInput
                readOnly
                labelWidth="40%"
                label="Hình thức thanh toán"
                value={data?.ten_ht_thanh_toan}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
            <Stack spacing="20px">
              <DatetimeInput
                labelWidth="40%"
                label="Ngày chứng từ"
                readOnly
                value={moment(data.ngay_ct).format('YYYY-MM-DD HH:mm')}
              />
              <DatetimeInput
                labelWidth="40%"
                label="Ngày tạo"
                readOnly
                value={moment(data.date_created).format('YYYY-MM-DD HH:mm')}
              />
              <TextInput
                readOnly
                labelWidth="40%"
                label="Người tạo"
                value={data.user_created}
              />
              <DatetimeInput
                labelWidth="40%"
                label="Ngày cập nhật cuối"
                readOnly
                value={moment(data.date_updated).format('YYYY-MM-DD HH:mm')}
              />
              <TextInput
                readOnly
                labelWidth="40%"
                label="Người cập nhật cuối"
                value={data.user_updated}
              />
            </Stack>
          </Grid>
        </Grid>
        {/* ghi chú */}
        <Stack
          direction="row"
          alignItems="flex-start"
          spacing="10px"
          sx={{ marginTop: '20px' }}
        >
          <Typography sx={{ fontWeight: 600 }}>Ghi chú:</Typography>
          <Typography>{data.dien_giai}</Typography>
        </Stack>
        {/* chương trình áp dụng */}
        {data?.exfields?.dmchietkhau?.length > 0 && (
          <Stack>
            <Typography sx={{ fontWeight: 600 }}>
              Chương trình áp dụng:
            </Typography>
            <List sx={{ padding: '5px 0' }}>
              {data?.exfields?.dmchietkhau.map((item) => (
                <ListItem key={item._id} sx={{ padding: 0 }}>
                  <ListItemIcon
                    sx={{ minWidth: '20px', color: 'success.main' }}
                  >
                    <FaRegCheckCircle size={16} />
                  </ListItemIcon>
                  <ListItemText sx={{ margin: 0 }}>
                    {item.ten_chietkhau}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Stack>
        )}
        {/* chi tiết hóa đơn */}
        {data?.details?.length > 0 && (
          <Stack spacing="5px" sx={{ marginTop: '20px' }}>
            <Typography sx={{ fontWeight: 600 }}>Chi tiết hóa đơn:</Typography>
            <TableDisplay
              columns={[
                {
                  name: 'Hàng hóa',
                  selector: (row) => `${row.ten_vt} (${row.ma_vt})`,
                  cell: (row) => {
                    return (
                      <Box>
                        <Typography>
                          {row.ten_vt} ({row.ma_vt})
                          {row.ma_lo && (
                            <Chip
                              sx={{
                                backgroundColor: 'secondary.main',
                                color: 'whitish.pureWhite',
                              }}
                              label={row.ma_lo}
                              size="small"
                              component="span"
                            />
                          )}
                        </Typography>
                        {row.dien_giai && (
                          <Typography>Ghi chú: {row.dien_giai}</Typography>
                        )}
                      </Box>
                    );
                  },
                  minWidth: '200px',
                  wrap: true,
                },
                {
                  name: 'Số lượng',
                  selector: (row) => row.sl_xuat,
                  width: '80px',
                  center: true,
                },
                {
                  name: 'Đơn vị tính',
                  selector: (row) => row.ma_dvt,
                  width: '100px',
                  wrap: true,
                  center: true,
                },
                {
                  name: 'Giá bán',
                  selector: (row) => row.gia_ban_nt,
                  format: (row) => numeralCustom(row.gia_ban_nt).format(),
                  wrap: true,
                  width: '120px',
                  center: true,
                },
                {
                  name: 'Tiền hàng',
                  selector: (row) => row.tien_nt,
                  format: (row) => numeralCustom(row.tien_nt).format(),
                  width: '120px',
                  center: true,
                },
                {
                  name: 'Tiền CK',
                  selector: (row) => row.tien_ck_nt,
                  format: (row) => numeralCustom(row.tien_ck_nt).format(),
                  width: '120px',
                  center: true,
                },
                {
                  name: 'Tiền xuất',
                  selector: (row) => row.tien_xuat_nt,
                  format: (row) => numeralCustom(row.tien_xuat_nt).format(),
                  width: '120px',
                  right: true,
                },
              ]}
              data={data.details || []}
            />
          </Stack>
        )}
        {/* tổng cộng */}
        {data?.details?.length > 0 && (
          <Box sx={{ marginTop: '10px' }}>
            <Grid container>
              <Grid item xs={12} md={7}></Grid>
              <Grid item xs={12} md={5}>
                <Stack spacing="10px">
                  <TextInput
                    readOnly
                    labelWidth="40%"
                    label="Tổng tiền hàng:"
                    value={numeralCustom(data.t_tien).format()}
                  />
                  <TextInput
                    readOnly
                    labelWidth="40%"
                    label="Chiết khấu sản phẩm:"
                    value={numeralCustom(data.t_ck).format()}
                  />
                  <TextInput
                    readOnly
                    labelWidth="40%"
                    label="Chiết khấu hóa đơn:"
                    value={numeralCustom(data.tien_ck_hd).format()}
                  />
                  <TextInput
                    readOnly
                    labelWidth="40%"
                    label="Tổng chiết khấu:"
                    value={numeralCustom(data.tc_ck).format()}
                  />
                  <TextInput
                    readOnly
                    labelWidth="40%"
                    label="Thành tiền:"
                    value={numeralCustom(data.t_tt).format()}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        )}
        {!hideAction && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing="10px"
            sx={{ marginTop: '20px' }}
          >
            {data?.trang_thai &&
              Number(data.trang_thai) === 0 &&
              allowAuthorize({ type: 'update', module: 'hd2' }) && (
                <ButtonBase
                  onClick={() => navigate('/order', { state: data })}
                  startIcon={<MdRecycling size={14} />}
                  sx={{
                    backgroundColor: 'secondary.main',
                    '&:hover': { backgroundColor: 'secondary.main' },
                  }}
                >
                  Xử lý đơn
                </ButtonBase>
              )}
            {data?.trang_thai &&
              Number(data.trang_thai) === 5 &&
              allowAuthorize({ type: 'add', module: 'hd3' }) && (
                <ButtonBase
                  onClick={() => setOpenFormReturn(true)}
                  startIcon={<IoReturnUpBackOutline size={14} />}
                  sx={{
                    backgroundColor: 'warning.main',
                    '&:hover': { backgroundColor: 'warning.main' },
                  }}
                >
                  Trả hàng
                </ButtonBase>
              )}
            {allowAuthorize({ type: 'delete', module: 'hd2' }) && (
              <ButtonBase
                startIcon={<IoMdTrash size={14} />}
                sx={{
                  backgroundColor: 'error.main',
                  '&:hover': { backgroundColor: 'error.main' },
                }}
                onClick={() => {
                  showConfirm({
                    title: 'Xác nhận xóa',
                    content: (
                      <Box sx={{ padding: '0 10px' }}>
                        <Typography
                          sx={{ fontSize: '14px', textAlign: 'center' }}
                        >
                          Bạn có chắc muốn xóa dòng này không ?
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '12px',
                            textAlign: 'center',
                            fontStyle: 'italic',
                            color: 'primary.main',
                            marginTop: '10px',
                          }}
                        >
                          Lưu ý: Dữ liễu đã xóa sẽ không thể khôi phục.
                        </Typography>
                      </Box>
                    ),
                    onConfirm: handleDelete,
                  });
                }}
              >
                Xóa
              </ButtonBase>
            )}
          </Stack>
        )}
      </Box>
    </>
  );
}

export default ExpandHd2;
