import React from 'react';
import ModalBase from '../../modal/ModalBase';
import ButtonBase from '../../button/ButtonBase';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { Grid } from '@mui/material';
import TextInput from '../../input/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import AreaInput from '~/components/input/AreaInput';

const schema = yup.object({
  ma_cn: yup.string().required('Vui lòng nhập mã chức năng'),
  ten_mau_in: yup.string().required('Vui lòng nhập tên mẫu in'),
});

export default function FormRPT({
  open,
  defaultValues,
  isEdit = false,
  handleClose = () => {},
  setLoad = () => {},
}) {
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const handleSave = async (values) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'rpt',
      data: values,
      handleClose,
      reset,
      setLoad,
    });
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="700px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} mẫu in`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Mã chức năng"
            placeholder="Mã chức năng"
            name="ma_cn"
            register={register}
            errorMessage={errors?.ma_cn?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Tên mẫu in"
            placeholder="Tên mẫu in"
            name="ten_mau_in"
            required
            register={register}
            errorMessage={errors?.ten_mau_in?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <AreaInput label="Mẫu in" name="html_template" register={register} />
        </Grid>
      </Grid>
    </ModalBase>
  );
}
