import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import TextInput from '~/components/input/TextInput';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CheckboxInput from '~/components/input/CheckboxInput';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { numeralCustom, postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import AreaInput from '~/components/input/AreaInput';

const schemaBase = yup.object({
  ten: yup.string().required('Vui lòng nhập tên'),
});

const defaultData = {
  ten: '',
  tai_khoan_nh: '',
  chu_tai_khoan: '',
  ngan_hang: '',
  dien_giai: '',
  kieu_so1: 0,
  status: true,
};

function FormPTTT({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues || defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schemaBase),
  });

  const handleReset = () => {
    reset(originalData);
  };

  const generateDataPost = (values) => {
    return values;
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'ptthanhtoan',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="700px"
      title="phương thức thanh toán"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            label="Tên PT"
            placeholder="Thanh toán khi nhận hàng"
            name="ten"
            register={register}
            required
            errorMessage={errors?.ten?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            label="TK ngân hàng"
            placeholder="3829981"
            name="tai_khoan_nh"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            label="Chủ tài khoản"
            placeholder="Lê Kim Nhàn"
            name="chu_tai_khoan"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            label="Ngân hàng"
            placeholder="BIDV"
            name="ngan_hang"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="kieu_so1"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <TextInput
                  type="number"
                  label="Tỷ lệ CK"
                  labelWidth="30%"
                  value={numeralCustom(value).format()}
                  onChange={onChange}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AreaInput
            label="Mô tả"
            labelWidth="15%"
            placeholder="Nhập mô tả cho phương thức này"
            name="dien_giai"
            register={register}
          />
        </Grid>
        {isEdit && (
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, value } }) => (
                <CheckboxInput
                  label="Còn sử dụng"
                  name="status"
                  checked={value || false}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </ModalForm>
  );
}

export default FormPTTT;
