import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { v4 } from 'uuid';
import ButtonBase from '~/components/button/ButtonBase';
import ModalBase from '~/components/modal/ModalBase';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import TabsBase from '~/components/tabs/TabsBase';
import { TabPanel } from '@mui/lab';
import { useRef } from 'react';
import InfoTab from './InfoTab';
import DetailTab from './DetailTab';
import DescriptionTab from './DescriptionTab';
import moment from 'moment';

const schema = yup.object({
  ngay_ct: yup
    .date()
    .typeError('Vui lòng nhập ngày chứng từ')
    .required('Vui lòng nhập ngày chứng từ'),
  khoXuat: yup
    .object()
    .required('Vui lòng chọn kho xuất'),
  khoNhap: yup
    .object()
    .required('Vui lòng chọn kho nhập'),
  trangThai: yup
    .object()
    .required('Vui lòng chọn trạng thái'),
});

function FormPXDC({
  open,
  defaultValues,
  isEdit = false,
  handleClose = () => {},
  setLoad = () => {},
}) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          khoXuat: defaultValues.ma_kho_x
            ? {
                ma_kho: defaultValues.ma_kho_x,
                ten_kho: defaultValues.ten_kho_x,
              }
            : null,
          khoNhap: defaultValues.ma_kho_n
            ? {
                ma_kho: defaultValues.ma_kho_n,
                ten_kho: defaultValues.ten_kho_n,
              }
            : null,
          trangThai: defaultValues.trang_thai
            ? {
                ma_trang_thai: defaultValues.trang_thai,
                ten_trang_thai: defaultValues.ten_trang_thai,
              }
            : null,
          ngay_ct: defaultValues.ngay_ct
            ? moment(defaultValues.ngay_ct).format('YYYY-MM-DD')
            : null,
        }
      : {
          ngay_ct: moment().format('YYYY-MM-DD'),
        },
    resolver: yupResolver(schema),
  });
  const [details, setDetails] = useState(defaultValues?.details || []);
  const { asyncPostData, asyncPutData } = useApisContext();
  const tabRef = useRef();

  const generateDataPost = (values) => {
    const { khoXuat, khoNhap, trangThai, ...fields } = values;
    const result = {
      ...fields,
      ma_kho_x: khoXuat?.ma_kho,
      ten_kho_x: khoXuat?.ten_kho,
      ma_kho_n: khoNhap?.ma_kho,
      ten_kho_n: khoNhap?.ten_kho,
      trang_thai: trangThai?.ma_trang_thai || '',
      ten_trang_thai: trangThai?.ten_trang_thai || '',
    };
    result.details = details;
    return result;
  };

  // handle submit
  const handleSave = async (values) => {
    const dataPost = generateDataPost(values);
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'pdn',
      data: dataPost,
      handleClose,
      reset,
      setLoad,
    });
  };
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      if (Object.keys(errors).length > 0) {
        tabRef.current?.handleChange(null, '1');
      }
    }
  }, [errors]);

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="700px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} phiếu xuất điều chuyển`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <TabsBase
        tabLabels={[
          { label: 'Thông tin', value: '1' },
          { label: 'Chi tiết', value: '2' },
          { label: 'Diễn giải', value: '3' },
        ]}
        ref={tabRef}
      >
        <TabPanel value="1" sx={{ padding: '10px 0 0 0' }}>
          <InfoTab
            control={control}
            errors={errors}
            isEdit={isEdit}
            register={register}
          />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: '10px 0 0 0' }}>
          <DetailTab
            isEditMaster={isEdit}
            details={details}
            setDetails={setDetails}
          />
        </TabPanel>
        <TabPanel value="3" sx={{ padding: '10px 0 0 0' }}>
          <DescriptionTab register={register} />
        </TabPanel>
      </TabsBase>
    </ModalBase>
  );
}

export default FormPXDC;
