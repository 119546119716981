import React, { useEffect, useState } from 'react';
import { Chip, Skeleton, Stack } from '@mui/material';
import FormLo from '../form/lo/FormLo';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import { formatDateDisplay } from '~/utils/helpers';
import moment from 'moment';
import TextSelectApiInput from '../input/TextSelectApiInput';

function SelectLo({ detail, onLoChange = (lo) => {} }) {
  const { asyncSearchList } = useApisContext();
  const showAlert = useAlertContext();
  const [tonKhos, setTonKhos] = useState([]);
  const [selectedLo, setSelectedLo] = useState();
  const [loading, setLoading] = useState(false);

  // Nếu hàng hóa chỉ có một lô thì tự động chọn
  const getLoAuto = async () => {
    try {
      setLoading(true);
      const condition = {
        apiCode: 'dmlo',
        condition: {
          page: 1,
          limit: 99999,
          q: {
            ma_vt: detail?.ma_vt,
            status: true,
            han_sd: { $gt: moment() },
          },
        },
      };
      const resp = await asyncSearchList(condition);
      if (resp && Array.isArray(resp) && resp.length > 0) {
        setSelectedLo(resp[0]);
        onLoChange(resp[0]);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    } finally {
      setLoading(false);
    }
  };

  // Nếu đã có lô thì lấy lại thông tin lô
  const getLo = async () => {
    try {
      setLoading(true);
      const condition = {
        apiCode: 'dmlo',
        condition: {
          page: 1,
          limit: 99999,
          q: {
            ma_vt: detail?.ma_vt,
            ma_lo: detail?.ma_lo,
            status: true,
            han_sd: { $gt: moment() },
          },
        },
      };
      const resp = await asyncSearchList(condition);
      if (resp && Array.isArray(resp) && resp.length > 0) {
        setSelectedLo(resp[0]);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    } finally {
      setLoading(false);
    }
  };

  const getTonKho = async () => {
    try {
      const resp = await asyncSearchList({
        apiCode: 'tontucthoi',
        condition: { page: 1, limit: 999999, q: { ma_vt: detail?.ma_vt } },
      });
      setTonKhos(resp);
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    }
  };

  // handle delete lo
  const handleDeleteLo = async () => {
    setSelectedLo(null);
    onLoChange(null);
  };

  useEffect(() => {
    getTonKho();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail.ma_vt]);

  useEffect(() => {
    if (detail.ma_lo) {
      getLo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail.ma_lo]);
  useEffect(() => {
    if (!detail.ma_lo) {
      getLoAuto();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TextSelectApiInput
      apiCode="dmlo"
      placeholder="Tìm kiếm theo mã lô"
      selectedValue={selectedLo}
      onSelect={(val) => {
        setSelectedLo(val);
        onLoChange(val);
      }}
      searchFileds={['ma_lo']}
      condition={{
        ma_vt: detail?.ma_vt,
        status: true,
        han_sd: { $gt: moment() },
      }}
      getOptionLabel={(option) => {
        const tonKho = tonKhos.find((t) => t.ma_lo === option.ma_lo);
        return `${option.ma_lo} - ${formatDateDisplay(
          option.han_sd
        )} - Tồn kho: ${!!tonKho ? tonKho.ton00 : 0}`;
      }}
      renderDisplay={(props) => {
        if (loading) {
          return (
            <Stack direction="row" spacing="4px" alignItems="center">
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ width: '10px', height: '14px' }}
              />
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ width: '20px', height: '14px' }}
              />
            </Stack>
          );
        }
        return (
          <>
            {!!selectedLo ? (
              <Chip
                label={`${selectedLo.ma_lo} - ${formatDateDisplay(
                  selectedLo.han_sd
                )}`}
                variant="filled"
                size="small"
                sx={{
                  backgroundColor: 'secondary.main',
                  color: 'whitish.pureWhite',
                  cursor: 'pointer',
                  '& .MuiSvgIcon-root': {
                    color: 'whitish.pureWhite',
                    '&:hover': {
                      color: 'whitish.gray',
                    },
                  },
                  '&:hover': {
                    backgroundColor: 'secondary.main',
                  },
                }}
                onDelete={handleDeleteLo}
                {...props}
              />
            ) : (
              <Chip
                label="Chọn lô"
                variant="filled"
                size="small"
                sx={{
                  backgroundColor: 'error.main',
                  color: 'whitish.pureWhite',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'error.main',
                  },
                }}
                {...props}
              />
            )}
          </>
        );
      }}
      checkActive={(option) => option.ma_lo === selectedLo?.ma_lo}
      FormAdd={FormLo}
      menuWidth="300px"
    />
  );
}

export default SelectLo;
