import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import InfoSection from './info-section/InfoSection';
import ChiTietSection from './chitiet-section/ChiTietSection';
import { Stack } from '@mui/material';
import DescriptionSection from './description-section/DescriptionSection';
import VATSection from './vat-section/VATSection';
import ChiPhiSection from './chiphi-section/ChiPhiSection';

const schema = yup.object({
  kho: yup
    .object()
    .typeError('Vui lòng chọn kho')
    .required('Vui lòng chọn kho'),
  ngay_ct: yup
    .date()
    .typeError('Vui lòng nhập ngày chứng từ')
    .required('Vui lòng nhập ngày chứng từ'),
  nha_cung_cap: yup
    .object()
    .typeError('Vui lòng chọn nhà cung cấp')
    .required('Vui lòng chọn nhà cung cấp'),
  trang_thai: yup
    .object()
    .typeError('Vui lòng chọn trạng thái')
    .required('Vui lòng chọn trạng thái'),
});

export default function FormPN1({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const defaultData = {
    so_ct: '',
    ngay_ct: moment().format('YYYY-MM-DD HH:mm'),
    kho: null,
    trang_thai: null,
    nha_cung_cap: null,
  };
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        kho: {
          ma_kho: defaultValues?.ma_kho,
          ten_kho: defaultValues?.ten_kho,
        },
        nha_cung_cap: defaultValues.ma_kh
          ? {
              ma_kh: defaultValues?.ma_kh,
              ten_kh: defaultValues?.ten_kh,
            }
          : null,
        nhan_vien: defaultValues.ma_nv
          ? { ma_nv: defaultValues.ma_nv, ten_nv: defaultValues.ten_nv }
          : null,
        pttt: defaultValues.hinh_thuc_tt
          ? { ten: defaultValues.hinh_thuc_tt }
          : null,
        ngay_ct: moment(defaultValues.ngay_ct).format('YYYY-MM-DD HH:mm'),
        trang_thai: defaultValues.trang_thai
          ? {
              ma_trang_thai: defaultValues.trang_thai,
              ten_trang_thai: defaultValues.ten_trang_thai,
            }
          : null,
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });
  const [details, setDetails] = useState(defaultValues?.details || []);
  const [chiphis, setChiphis] = useState(defaultValues?.ctcpmhs || []);
  const [vats, setVats] = useState(defaultValues?.vatvaos || []);
  const [collapses, setCollapses] = useState({
    chitiet: true,
    chiphi: true,
    mota: true,
    vat: true,
  });

  const handleReset = () => {
    reset(originalData);
    setDetails(defaultValues?.details || []);
    setVats(defaultValues?.vatvaos || []);
    setChiphis(defaultValues?.ctcpmhs || []);
  };

  const generateDataPost = (values) => {
    const { kho, nha_cung_cap, trang_thai, nhan_vien, pttt, ...data } = values;
    const result = {
      ...data,
      tk_co: '1561',
      ma_kho: kho?.ma_kho || '',
      ten_kho: kho?.ten_kho || '',
      ma_kh: nha_cung_cap?.ma_kh || '',
      ten_kh: nha_cung_cap?.ten_kh || '',
      trang_thai: trang_thai?.ma_trang_thai || '',
      ten_trang_thai: trang_thai?.ten_trang_thai || '',
      ma_nv: nhan_vien?.ma_nv || '',
      ten_nv: nhan_vien?.ten_nv || '',
      hinh_thuc_tt: pttt?.ten || '',
      details,
      vatvaos: vats.map((item) => {
        if (item.new) {
          delete item._id;
        }
        return item;
      }),
      ctcpmhs: chiphis,
    };
    return result;
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'pn1',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };

  return (
    <ModalForm
      open={open}
      rows={rows}
      showNavigator={false}
      width="1200px"
      title="phiếu mua hàng"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Stack
        spacing="10px"
        sx={{
          width: '100%',
          padding: '10px',
          maxHeight: 'calc(90vh - 20px - 39px - 39px)',
          overflow: 'auto',
        }}
      >
        {/* Thong tin */}
        <InfoSection
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
        />
        {/* Chi tiet */}
        <ChiTietSection
          show={collapses.chitiet}
          setCollapses={setCollapses}
          details={details}
          setDetails={setDetails}
        />
        {/* vat */}
        <VATSection
          show={collapses.vat}
          setCollapses={setCollapses}
          vats={vats}
          setVats={setVats}
          defaultValues={defaultValues}
        />
        {/* chi phi */}
        <ChiPhiSection
          chiphis={chiphis}
          setChiphis={setChiphis}
          setCollapses={setCollapses}
          show={collapses.chiphi}
        />
        {/* Mo ta */}
        <DescriptionSection
          show={collapses.mota}
          setCollapses={setCollapses}
          register={register}
        />
      </Stack>
    </ModalForm>
  );
}
