import React from 'react';
import MenuHover from '../menu/MenuHover';
import { useDispatch, useSelector } from 'react-redux';
import { Button, List, ListItemButton, ListItemText } from '@mui/material';
import { updateCurrentStore } from '~/redux/reducrers/store.reducer';
import { BiStore } from 'react-icons/bi';

function SelectStore() {
  const { data: stores, currentStore } = useSelector((state) => state.store);
  const dispatch = useDispatch();

  const handleSelectStore = (store) => {
    dispatch(updateCurrentStore(store));
  };

  return (
    <MenuHover
      content={
        <List disablePadding>
          {stores?.map((store) => (
            <ListItemButton
              key={store._id}
              onClick={() => handleSelectStore(store)}
            >
              <ListItemText
                sx={{ '& .MuiTypography-root': { fontSize: '12px' } }}
              >
                {store.ten_kho}
              </ListItemText>
            </ListItemButton>
          ))}
        </List>
      }
    >
      <Button
        variant="contained"
        sx={{
          textWrap: 'nowrap',
          fontWeight: '400',
          textTransform: 'none',
          backgroundColor: 'whitish.pureWhite',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: 'whitish.pureWhite',
            boxShadow: 'none',
          },
        }}
        endIcon={<BiStore size={14} />}
      >
        {currentStore?.ten_kho}
      </Button>
    </MenuHover>
  );
}

export default SelectStore;
