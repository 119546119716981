import React, { useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  Menu,
  Stack,
  Typography,
} from '@mui/material';
import { numeralCustom } from '~/utils/helpers';
import { RiCopperCoinLine } from 'react-icons/ri';

const currencies = [
  1000, 2000, 5000, 10000, 20000, 50000, 100000, 200000, 500000,
];

function Currentcy() {
  const [anchor, setAnchor] = useState(null);

  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <>
      <Menu open={!!anchor} anchorEl={anchor} onClose={handleClose}>
        <Stack spacing="5px" sx={{ padding: '0 10px', width: '220px' }}>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
            Nhập mệnh giá
          </Typography>
          <Grid container spacing="2px">
            <Grid item xs={12}>
              <Button sx={{ width: '100%' }} variant="contained">
                {numeralCustom(340000).format()}
              </Button>
            </Grid>
            {currencies.map((currency) => (
              <Grid key={currency} item xs={4}>
                <Button sx={{ width: '100%' }} variant="outlined">
                  {numeralCustom(currency).format()}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Menu>
      <IconButton
        sx={{ padding: '4px' }}
        onClick={(e) => setAnchor(e.currentTarget)}
      >
        <RiCopperCoinLine size={14} />
      </IconButton>
    </>
  );
}

export default Currentcy;
