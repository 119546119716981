import React from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import LogoIcon from '~/assets/img/logo.svg';

function Logo() {
  return (
    <Stack direction="row" alignItems="center" spacing="5px">
      <Avatar src={LogoIcon} sx={{ width: '50px', height: '50px' }} />
      <Typography
        sx={{ textDecoration: 'none', fontSize: '20px', fontWeight: 600 }}
      >
        Thiên Thủy
      </Typography>
    </Stack>
  );
}

export default Logo;
