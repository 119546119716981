import React from 'react';
import { Avatar, Box, Container, Stack, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import TypeIt from 'typeit-react';
import InputForm from '~/components/input/InputForm';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '~/redux/actions/auth.action';
import BgLogin from '~/assets/img/bg_login.jpg';
import Logo from '~/assets/img/logo.svg';
import useAlertContext from '~/hooks/hookContext/useAlertContext';

const schema = yup.object({
  email: yup.string().required('Vui lòng nhập tài khoản đăng nhập'),
  password: yup.string().required('Vui lòng nhập mật khẩu'),
});

function LoginPage() {
  const dataLogin = useSelector((state) => state.auth.login);
  const showAlert = useAlertContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // onSubmit
  const onSubmit = (values) => {
    return new Promise((resovle) => {
      setTimeout(async () => {
        await loginUser({
          username: values.email,
          password: values.password,
          dispatch,
          navigate,
          showAlert,
        });
        resovle();
      }, 500);
    });
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          height: '100vh',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 5,
            backgroundColor: '#00000066',
          }}
        ></Box>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
          }}
        >
          <Avatar
            src={BgLogin}
            sx={{ width: '100%', height: '100%', borderRadius: 0 }}
          />
        </Box>
        <Container
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            paddingTop: '40px',
            paddingBottom: '40px',
            zIndex: 10,
            overflow: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          maxWidth="xl"
        >
          <Box
            sx={{
              width: '95%',
              maxWidth: '556px',
              backgroundColor: 'whitish.pureWhite',
              margin: '0 auto',
              borderRadius: '10px',
              padding: '20px 40px',
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Avatar src={Logo} sx={{ width: '60px', height: '60px' }} />
            </Stack>
            <Typography
              sx={{
                color: 'primary.main',
                textAlign: 'center',
                fontWeight: 400,
                fontSize: '16px',
              }}
            >
              THIÊN THỦY
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontSize: '20px', fontWeight: 550, textAlign: 'center' }}
            >
              <TypeIt style={{ color: '#171725' }} options={{ loop: true }}>
                Chào mừng trở lại!
              </TypeIt>
            </Typography>
            <Box
              onSubmit={handleSubmit(onSubmit)}
              component="form"
              sx={{
                marginTop: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <InputForm
                label="Tài khoản đăng nhập"
                id="email"
                name="email"
                type="text"
                isRequired
                placeholder="email@gmail.com"
                register={register}
                errorMessage={errors?.email?.message}
              />
              <InputForm
                label="Mật khẩu"
                id="password"
                type="password"
                name="password"
                isRequired
                placeholder="Nhập mật khẩu"
                register={register}
                errorMessage={errors?.password?.message}
              />

              <LoadingButton
                type="submit"
                sx={{
                  textTransform: 'none',
                  color: 'whitish.pureWhite',
                  fontSize: '16px',
                  fontWeight: 600,
                  height: '52px',
                  boxShadow: 'none',
                }}
                variant="contained"
                loading={dataLogin.isFetching}
              >
                Đăng nhập
              </LoadingButton>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default LoginPage;
