import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Container, Grid } from '@mui/material';
import Header from './components/header/Header';
import PrivateRoute from '~/routes/PrivateRoute';
import { ORDER_HEADER_HEIGHT } from '~/utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  createHd,
  initRenderHds,
  updateCurrentHd,
  updateCustomer,
  updateHds,
  updateIndexHd,
  updateOneHd,
} from '~/redux/reducrers/order.reducer';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import { v4 } from 'uuid';
import Left from './components/left/Left';
import Right from './components/right/Right';
import useBackdropContext from '~/hooks/hookContext/useBackdropContext';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { useLocation } from 'react-router-dom';
import useAuthorize from '~/hooks/useAuthorize';
// import ForceCustomer from './components/force-customer/ForceCustomer';
import { isArray } from '~/utils/helpers';

const OrderContext = createContext();

function OrderPage() {
  const allowAuthorize = useAuthorize();
  const { currentStore } = useSelector((state) => state.store);
  const { hds, renderHds, indexHd, currentHd } = useSelector(
    (state) => state.order
  );
  const { user } = useSelector((state) => state.auth);
  const { asyncSearchList, asyncPostData, asyncPutData } = useApisContext();
  const showAlert = useAlertContext();
  const [, setBackdrop] = useBackdropContext();
  const [products, setProducts] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();

  const getOriginCustomer = async () => {
    try {
      const resp = await asyncSearchList({
        apiCode: 'customer',
        condition: {
          page: 1,
          limit: 1,
          q: { ma_kh: currentHd?.ma_kh, kh_yn: true, ncc_yn: { $ne: true } },
        },
      });
      if (resp && isArray(resp) && resp.length > 0) {
        dispatch(updateCustomer(resp[0]));
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    }
  };

  const handleSelectHdInLocation = (locationHd) => {
    // Nếu chưa có hóa đơn này thì thêm nó vào
    const hdsClone = hds.filter((h) => h._id !== locationHd._id);
    (hdsClone || []).unshift(locationHd);
    dispatch(updateHds(hdsClone));
    dispatch(updateIndexHd(0));
  };

  const handleCreateHd = async () => {
    try {
      const respNv = await asyncSearchList({
        apiCode: 'dmnv',
        condition: { page: 1, limit: 1, q: { user: user?.email } },
      });
      const hd = {
        _id: v4(),
        tk_no: '1111',
        ma_kho: currentStore?.ma_kho || '',
        ten_kho: currentStore?.ten_kho || '',
        ma_nv: respNv?.[0]?.ma_nv || '',
        ten_nv: respNv?.[0]?.ten_nv || '',
        ht_thanh_toan: '',
        ten_ht_thanh_toan: '',
        // ngay_ct: moment().toISOString(),
        exfields: {},
        details: [],
      };
      dispatch(createHd(hd));
      return hd;
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    }
  };

  const handleUpdateCurrentHd = (updateData = {}) => {
    const hdData = { ...currentHd, ...updateData };
    dispatch(updateOneHd(hdData));
  };

  const handleInitRenderHds = async () => {
    if (!currentStore || !currentStore.ma_kho) return;
    const hdsInStore = (hds || []).filter(
      (hd) => hd.ma_kho === currentStore.ma_kho
    );
    if (hdsInStore.length === 0) {
      const hd = await handleCreateHd();
      dispatch(initRenderHds([hd]));
      dispatch(updateIndexHd(0));
    } else {
      dispatch(initRenderHds(hdsInStore));
      if (!hdsInStore[indexHd]) {
        dispatch(updateIndexHd(0));
      }
    }
  };
  const saveHd = async (trang_thai = 0) => {
    try {
      setBackdrop(true);
      const { _id, details, ...fields } = currentHd;
      const method = !!trang_thai ? asyncPutData : asyncPostData;
      const hdToSave = {
        ...fields,
        trang_thai,
        ten_trang_thai: 'Đặt hàng',
        details: details.map((detail) => {
          const { _id, ...detailData } = detail;
          return {
            ...detailData,
            gia_ban_nt: detailData.gia_ban,
            tien_nt: detailData.tien,
            tien_ck_nt: detailData.tien_ck,
            tien_thue_nt: detailData.tien_thue,
            tien_xuat: detailData.tien - detailData.tien_ck,
            tien_xuat_nt: detailData.tien - detailData.tien_ck,
          };
        }),
      };
      if(!hdToSave.ma_kh) {
        showAlert({
          type: 'warning',
          message: 'Vui lòng chọn khách hàng',
        });
        return;
      }
      if (!hdToSave.ht_thanh_toan) {
        showAlert({
          type: 'warning',
          message: 'Vui lòng chọn phương thức thanh toán',
        });
        return;
      }

      const resp = await method({
        apiCode: 'hd2',
        data: hdToSave,
        uniqueValue: _id,
      });
      if (resp?.error) {
        showAlert({ type: 'error', message: resp?.error || 'Có lỗi xảy ra' });
        return;
      }
      const newHds = hds.filter((item) => item._id !== currentHd._id);
      dispatch(updateHds(newHds));
      showAlert({ type: 'success', message: 'Đặt hàng thành công' });
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    } finally {
      setBackdrop(false);
    }
  };

  const allowSelectLo = useMemo(() => {
    return user?.admin || allowAuthorize({ type: 'update', module: 'dmlo' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (hds?.length > 0) {
      handleInitRenderHds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore]);

  useEffect(() => {
    if (!!renderHds && renderHds.length > 0 && renderHds[indexHd]) {
      dispatch(updateCurrentHd(renderHds[indexHd]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexHd, renderHds]);

  useEffect(() => {
    if (hds) {
      handleInitRenderHds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hds]);

  useEffect(() => {
    if (location?.state) {
      handleSelectHdInLocation(location.state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  useEffect(() => {
    if (currentHd?.ma_kh) {
      getOriginCustomer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHd?.ma_kh]);

  return (
    <PrivateRoute>
      <OrderContext.Provider
        value={{
          handleCreateHd,
          handleUpdateCurrentHd,
          saveHd,
          allowSelectLo,
          products,
          setProducts,
        }}
      >
        {/* <GetProducts /> */}
        {/* <ForceCustomer /> */}
        <Box>
          <Header headerHeight={ORDER_HEADER_HEIGHT} />
          <Box
            sx={{
              width: '100%',
              height: `calc(100vh - ${ORDER_HEADER_HEIGHT})`,
              overflow: 'auto',
              backgroundColor: 'primary.opacity',
            }}
          >
            <Box sx={{ height: '100%', padding: '5px 0' }}>
              <Container maxWidth="xl" sx={{ height: '100%' }}>
                <Grid container spacing="5px">
                  <Grid item xs={12} md={8}>
                    <Left />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Right />
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Box>
        </Box>
      </OrderContext.Provider>
    </PrivateRoute>
  );
}

export default OrderPage;

export const useOrderContext = () => {
  const value = useContext(OrderContext);
  if (!value)
    throw new Error('Order context must be used inside Order Provider');
  return value;
};
