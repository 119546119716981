import React, { useState } from 'react';
import { Box, Chip, Grid, Stack, Typography } from '@mui/material';
import TextInput from '../input/TextInput';
import { LABEL_KHO } from '~/utils/label.constant';
import DatetimeInput from '../input/DatetimeInput';
import moment from 'moment';
import TableDisplay from '../table/TableDisplay';
import ButtonBase from '../button/ButtonBase';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useConfirmContext from '~/hooks/hookContext/useConfirmContext';
import { formatDateDisplay, numeralCustom } from '~/utils/helpers';
import { KIEU_PHAN_BO } from '~/utils/constants';
import FormPN5 from '../form/pn5/FormPN5';
import { IoReturnUpBackOutline } from 'react-icons/io5';
import useAuthorize from '~/hooks/useAuthorize';

function ExpandPN1({ data, openForm, setLoad }) {
  const allowAuthorize = useAuthorize();
  const showAlert = useAlertContext();
  const showConfirm = useConfirmContext();
  const { asyncDelete } = useApisContext();
  const [openFormReturn, setOpenFormReturn] = useState(false);

  const handleDelete = async () => {
    try {
      const resp = await asyncDelete({ apiCode: 'pn1', uniqueValue: data._id });
      if (!resp?.error) {
        setLoad((prev) => prev + 1);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong',
      });
    }
  };

  return (
    <>
      <FormPN5
        open={openFormReturn}
        setLoad={setLoad}
        handleClose={() => setOpenFormReturn(false)}
        defaultValues={{ pn1: data }}
      />
      <Box
        sx={{
          padding: '20px',
          backgroundColor: 'whitish.graySoft',
          border: '1px solid',
          borderColor: 'primary.main',
          borderRadius: '0 0 4px 4px',
          position: 'relative',
          zIndex: 0,
        }}
      >
        <Grid container spacing="20px">
          <Grid item xs={12} md={5}>
            <Stack spacing="20px">
              <TextInput
                readOnly
                labelWidth="40%"
                label="Số chứng từ"
                value={data.so_ct}
              />
              <TextInput
                readOnly
                labelWidth="40%"
                label={LABEL_KHO}
                value={data.ten_kho}
              />
              <TextInput
                readOnly
                labelWidth="40%"
                label="Nhà cung cấp"
                value={data.ten_kh}
              />
              <TextInput
                readOnly
                labelWidth="40%"
                label="Hình thức thanh toán"
                value={data.hinh_thuc_tt}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
            <Stack spacing="20px">
              <DatetimeInput
                labelWidth="40%"
                label="Ngày chứng từ"
                readOnly
                value={moment(data.ngay_ct).format('YYYY-MM-DD HH:mm')}
              />
              <DatetimeInput
                labelWidth="40%"
                label="Ngày tạo"
                readOnly
                value={moment(data.date_created).format('YYYY-MM-DD HH:mm')}
              />
              <TextInput
                readOnly
                labelWidth="40%"
                label="Người tạo"
                value={data.user_created}
              />
              <DatetimeInput
                labelWidth="40%"
                label="Ngày cập nhật cuối"
                readOnly
                value={moment(data.date_updated).format('YYYY-MM-DD HH:mm')}
              />
              <TextInput
                readOnly
                labelWidth="40%"
                label="Người cập nhật cuối"
                value={data.user_updated}
              />
            </Stack>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          alignItems="flex-start"
          spacing="10px"
          sx={{ marginTop: '20px' }}
        >
          <Typography sx={{ fontWeight: 600 }}>Ghi chú:</Typography>
          <Typography>{data.dien_giai}</Typography>
        </Stack>
        {data?.details?.length > 0 && (
          <Stack spacing="5px" sx={{ marginTop: '20px' }}>
            <Typography sx={{ fontWeight: 600 }}>Chi tiết mua:</Typography>
            <TableDisplay
              columns={[
                {
                  name: 'Hàng hóa',
                  selector: (row) => `${row.ten_vt} (${row.ma_vt})`,
                  cell: (row) => {
                    return (
                      <Typography>
                        {row.ten_vt} ({row.ma_vt})
                        {row.ma_lo && (
                          <Chip
                            sx={{
                              backgroundColor: 'secondary.main',
                              color: 'whitish.pureWhite',
                            }}
                            label={row.ma_lo}
                            size="small"
                            component="span"
                          />
                        )}
                      </Typography>
                    );
                  },
                  minWidth: '200px',
                  wrap: true,
                },
                {
                  name: 'Số lượng',
                  selector: (row) => row.sl_nhap,
                  width: '100px',
                  wrap: true,
                  center: true,
                },
                {
                  name: 'Đơn vị tính',
                  selector: (row) => row.ma_dvt,
                  width: '100px',
                  wrap: true,
                  center: true,
                },
                {
                  name: 'Giá nhập',
                  selector: (row) => row.gia_von_nt,
                  format: (row) => numeralCustom(row.gia_von_nt).format(),
                  width: '120px',
                  wrap: true,
                  center: true,
                },
                {
                  name: 'Tiền hàng',
                  selector: (row) => row.tien_hang_nt,
                  format: (row) => numeralCustom(row.tien_hang_nt).format(),
                  width: '100px',
                  wrap: true,
                  center: true,
                },
                {
                  name: 'Tiền CK',
                  selector: (row) => row.tien_ck_nt,
                  format: (row) => numeralCustom(row.tien_ck_nt).format(),
                  width: '100px',
                  wrap: true,
                  center: true,
                },
                {
                  name: 'Tiền phí',
                  selector: (row) => row.tien_phi,
                  format: (row) => numeralCustom(row.tien_phi).format(),
                  width: '100px',
                  wrap: true,
                  center: true,
                },
                {
                  name: 'Tiền nhập',
                  selector: (row) => row.tien_nhap_nt,
                  format: (row) => numeralCustom(row.tien_nhap_nt).format(),
                  width: '100px',
                  wrap: true,
                  right: true,
                },
              ]}
              data={data.details || []}
            />
          </Stack>
        )}
        {data?.vatvaos?.length > 0 && (
          <Stack spacing="5px" sx={{ marginTop: '20px' }}>
            <Typography sx={{ fontWeight: 600 }}>VAT:</Typography>
            <TableDisplay
              columns={[
                {
                  name: 'Số hóa đơn',
                  selector: (row) => row.so_hd,
                  left: true,
                  wrap: true,
                  width: '100px',
                },
                {
                  name: 'Ngày hóa đơn',
                  selector: (row) => row.ngay_hd,
                  format: (row) =>
                    formatDateDisplay(row.ngay_hd, 'DD/MM/YYYY HH:mm'),
                  left: true,
                  wrap: true,
                  minWidth: '120px',
                },
                {
                  name: 'Thuế suất (%)',
                  selector: (row) => row.ma_thue,
                  left: true,
                  wrap: true,
                  minWidth: '120px',
                },
                {
                  name: 'Tiền hàng',
                  selector: (row) => row.t_tien_nt,
                  format: (row) => numeralCustom(row.t_tien_nt).format(),
                  left: true,
                  wrap: true,
                  minWidth: '120px',
                },
                {
                  name: 'Tiền thuế',
                  selector: (row) => row.t_thue_nt,
                  format: (row) => numeralCustom(row.t_thue_nt).format(),
                  left: true,
                  wrap: true,
                  minWidth: '120px',
                },
                {
                  name: 'Nhà cung cấp',
                  selector: (row) => row.ten_kh,
                  right: true,
                  wrap: true,
                  minWidth: '150px',
                },
              ]}
              data={data.vatvaos || []}
            />
          </Stack>
        )}
        {data?.ctcpmhs?.length > 0 && (
          <Stack spacing="5px" sx={{ marginTop: '20px' }}>
            <Typography sx={{ fontWeight: 600 }}>Chi phí mua hàng:</Typography>
            <TableDisplay
              columns={[
                {
                  name: 'Tên chi phí',
                  selector: (row) => row.ten_cp,
                  left: true,
                  wrap: true,
                },
                {
                  name: 'Tiền chi phí',
                  selector: (row) => row.tien_cp_nt,
                  format: (row) => numeralCustom(row.tien_cp_nt).format(),
                  center: true,
                },
                {
                  name: 'Kiểu phân bổ',
                  selector: (row) => {
                    const kieuPb = KIEU_PHAN_BO.find(
                      (item) => item.value === row.kieu_pb
                    );
                    return kieuPb?.name || row.kieu_pb;
                  },
                  right: true,
                },
              ]}
              data={data.ctcpmhs || []}
            />
          </Stack>
        )}
        {data?.details?.length > 0 && (
          <Box sx={{ marginTop: '10px' }}>
            <Grid container>
              <Grid item xs={12} md={7}></Grid>
              <Grid item xs={12} md={5}>
                <Stack spacing="10px">
                  <TextInput
                    readOnly
                    labelWidth="40%"
                    label="Tổng tiền hàng:"
                    value={numeralCustom(data.t_tien_hang).format()}
                  />
                  <TextInput
                    readOnly
                    labelWidth="40%"
                    label="Tổng chiết khấu:"
                    value={numeralCustom(data.t_ck).format()}
                  />
                  <TextInput
                    readOnly
                    labelWidth="40%"
                    label="Tổng thuế (VAT):"
                    value={numeralCustom(data.t_thue_vao).format()}
                  />
                  <TextInput
                    readOnly
                    labelWidth="40%"
                    label="Tổng chi phí:"
                    value={numeralCustom(data.t_cp_cpb).format()}
                  />
                  <TextInput
                    readOnly
                    labelWidth="40%"
                    label="Thành tiền:"
                    value={numeralCustom(data.t_tt).format()}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing="10px"
          sx={{ marginTop: '20px' }}
        >
          {Number(data.trang_thai || 0) !== 5 &&
            allowAuthorize({ type: 'update', module: 'pn1' }) && (
              <ButtonBase
                sx={{
                  backgroundColor: 'secondary.main',
                  '&:hover': { backgroundColor: 'secondary.main' },
                }}
                onClick={openForm}
              >
                Chỉnh sửa
              </ButtonBase>
            )}
          {data?.trang_thai &&
            Number(data.trang_thai) === 5 &&
            allowAuthorize({ type: 'add', module: 'pn5' }) && (
              <ButtonBase
                onClick={() => setOpenFormReturn(true)}
                startIcon={<IoReturnUpBackOutline size={14} />}
                sx={{
                  backgroundColor: 'warning.main',
                  '&:hover': { backgroundColor: 'warning.main' },
                }}
              >
                Trả hàng
              </ButtonBase>
            )}
          {allowAuthorize({ type: 'delete', module: 'pn1' }) && (
            <ButtonBase
              sx={{
                backgroundColor: 'error.main',
                '&:hover': { backgroundColor: 'error.main' },
              }}
              onClick={() => {
                showConfirm({
                  title: 'Xác nhận xóa',
                  content: (
                    <Box sx={{ padding: '0 10px' }}>
                      <Typography
                        sx={{ fontSize: '14px', textAlign: 'center' }}
                      >
                        Bạn có chắc muốn xóa dòng này không ?
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          textAlign: 'center',
                          fontStyle: 'italic',
                          color: 'primary.main',
                          marginTop: '10px',
                        }}
                      >
                        Lưu ý: Dữ liễu đã xóa sẽ không thể khôi phục.
                      </Typography>
                    </Box>
                  ),
                  onConfirm: handleDelete,
                });
              }}
            >
              Xóa
            </ButtonBase>
          )}
        </Stack>
      </Box>
    </>
  );
}

export default ExpandPN1;
