import { Grid, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import TextInput from '../../input/TextInput';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import CheckboxInput from '~/components/input/CheckboxInput';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import { LABEL_KHO } from '~/utils/label.constant';

const schema = yup.object({
  ma_kho: yup.string().required('Vui lòng nhập mã kho'),
  ten_kho: yup.string().required('Vui lòng nhập tên kho'),
  email: yup.string().email('Email không đúng định dạng'),
  dien_thoai: yup.string().matches(/(84|0[3|5|7|8|9])+([0-9]{8})\b/g, {
    message: 'Số điện thoại không hợp lệ',
    excludeEmptyString: true,
  }),
});

const defaultData = {
  ma_kho: '',
  ten_kho: '',
  dia_chi: '',
  email: '',
  dien_thoai: '',
  status: true,
};

export default function FormKho({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        dien_thoai: defaultValues.exfields?.dien_thoai
          ? defaultValues.exfields?.dien_thoai
          : '',
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });
  const handleReset = () => {
    reset(originalData);
  };

  const handleSave = async (values, mode) => {
    const dataPost = cloneDeep(values);
    dataPost.exfields = dataPost.exfields || {};
    dataPost.exfields.dien_thoai = values.dien_thoai || '';
    // dataPost.printers = [
    //   {
    //     id_mau_in: '650abcd2e2989ec491c6d351',
    //   },
    // ];
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'dmkho',
      data: dataPost,
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };

  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <>
      <ModalForm
        open={open}
        rows={rows}
        width="800px"
        title={LABEL_KHO}
        isEdit={isEdit}
        isSubmitting={isSubmitting}
        currentIndex={currentIndex}
        handleSave={handleSave}
        handleClose={handleClose}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        setCurrentIndex={setCurrentIndex}
      >
        <Grid container spacing="20px">
          <Grid item xs={12} md={6}>
            <Stack spacing="20px">
              <TextInput
                labelWidth="30%"
                readOnly={isEdit}
                label={`Mã ${LABEL_KHO}`}
                placeholder="kcty"
                name="ma_kho"
                register={register}
                required
                errorMessage={errors?.ma_kho?.message}
              />
              <TextInput
                labelWidth="30%"
                label={`Tên ${LABEL_KHO}`}
                placeholder="Chi nhánh trung tâm"
                name="ten_kho"
                register={register}
                required
                errorMessage={errors?.ten_kho?.message}
              />
              {isEdit && (
                <Controller
                  control={control}
                  name="status"
                  render={({ field: { onChange, value } }) => (
                    <CheckboxInput
                      label="Còn hoạt động"
                      name="status"
                      checked={value || false}
                      onChange={onChange}
                    />
                  )}
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing="20px">
              <TextInput
                label="Điện thoại"
                placeholder="Số điện thoại"
                name="dien_thoai"
                register={register}
                errorMessage={errors?.dien_thoai?.message}
              />
              <TextInput
                label="Email"
                placeholder="Email liên hệ"
                name="email"
                register={register}
                errorMessage={errors?.email?.message}
              />

              <TextInput
                label="Địa chỉ"
                placeholder="Địa điểm"
                name="dia_chi"
                register={register}
              />
            </Stack>
          </Grid>
        </Grid>
      </ModalForm>
    </>
  );
}
