import React from 'react';
import { Grid, Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import CheckboxInput from '~/components/input/CheckboxInput';
import DatetimeInput from '~/components/input/DatetimeInput';
import { LABEL_KHO } from '~/utils/label.constant';
import SelectInput from '~/components/input/SelectInput';
import { LOAI_CHIET_KHAU } from '~/utils/constants';

function InfoSection({ register, errors, control, isEdit }) {
  return (
    <CollapseSection title="Thông tin chung" show>
      <Grid container spacing="20px">
        <Grid item xs={12} md={6}>
          <Stack spacing="20px">
            <TextInput
              required
              labelWidth="30%"
              label="Mã CK"
              placeholder="Nhập mã chiết khấu"
              name="ma_chietkhau"
              register={register}
              errorMessage={errors?.ma_chietkhau?.message}
            />
            <TextInput
              required
              labelWidth="30%"
              label="Tên CK"
              placeholder="Tên chiết khấu"
              name="ten_chietkhau"
              register={register}
              errorMessage={errors?.ten_chietkhau?.message}
            />
            <Controller
              control={control}
              name="kho"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  required
                  labelWidth="30%"
                  label={LABEL_KHO}
                  apiCode="dmkho"
                  placeholder={`Chọn ${LABEL_KHO}`}
                  searchFileds={['ma_kho', 'ten_kho']}
                  condition={{ status: true }}
                  getOptionLabel={(option) => option.ten_kho}
                  selectedValue={value}
                  value={value || { ma_kho: '', ten_kho: '' }}
                  onSelect={onChange}
                  errorMessage={errors?.kho?.message}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing="20px">
            <Controller
              control={control}
              name="hieu_luc_tu"
              render={({ field: { onChange, value } }) => (
                <DatetimeInput
                  required
                  label="Từ ngày"
                  labelWidth="30%"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors?.hieu_luc_tu?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="hieu_luc_den"
              render={({ field: { onChange, value } }) => (
                <DatetimeInput
                  required
                  label="Đến ngày"
                  labelWidth="30%"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors?.hieu_luc_den?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="loai_chiet_khau"
              render={({ field: { onChange, value } }) => (
                <SelectInput
                  required
                  readOnly={isEdit}
                  label="Loại chiết khấu"
                  labelWidth="30%"
                  placeholder="Chọn loại chiết khấu"
                  value={value || { value: '', label: '' }}
                  selectedValue={value}
                  onSelect={onChange}
                  options={LOAI_CHIET_KHAU}
                  getOptionLabel={(option) => option.label}
                  errorMessage={errors?.loai_chiet_khau?.message}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, value } }) => (
                <CheckboxInput
                  label="Phát hành"
                  name="status"
                  checked={value || false}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="newsfeed"
              render={({ field: { onChange, value } }) => (
                <CheckboxInput
                  label="Áp dụng offline"
                  checked={value || false}
                  onChange={onChange}
                />
              )}
            />
          </Stack>
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default InfoSection;
