import React, { useState, useEffect, useMemo } from 'react';
import { Box, Grid, Stack, Switch, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useResponsive from '~/hooks/useResponsive';
import DrawerBase from '../drawer/DrawerBase';
import TableDisplay from '../table/TableDisplay';
import BarChart from '../chart/BarChart';
import { cloneDeep } from 'lodash';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import { isArray } from 'lodash';

function ReportBase({ report }) {
  const theme = useTheme();
  const showAlert = useAlertContext();
  const mdMatches = useResponsive({ matchKey: 'up', breakpoint: 'md' });
  const { asyncGetReport } = useApisContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isChart, setIsChart] = useState(false);
  const [openDrawerFilter, setOpenDrawerFilter] = useState(false);
  const [queryObject, setQueryObject] = useState({});
  const [concern, setConcern] = useState(null);

  const getReport = async () => {
    try {
      setLoading(true);
      const resp = await asyncGetReport({
        apiCode: concern.api,
        queryObject,
      });
      if (isArray(resp)) {
        if (concern.convertData) {
          const dataConverted = concern.convertData(resp);
          setData(dataConverted);
        }
      } else {
        showAlert({
          type: 'error',
          message: resp?.message || resp?.error || 'Lỗi khi tải báo cáo',
        });
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || error?.error || 'Lỗi khi tải báo cáo',
      });
    } finally {
      setLoading(false);
    }
  };

  const renderFilter = () => (
    <Box
      sx={{
        width: '100%',
        overflow: 'auto',
        padding: '1px',
      }}
    >
      {report.Filter && (
        <report.Filter
          setQueryObject={setQueryObject}
          setConcern={setConcern}
        />
      )}
    </Box>
  );

  // data doanh thu, doanh thu thuan
  const doanhThus = useMemo(() => {
    const dataClone = cloneDeep(data) || [];
    dataClone?.pop();
    return (dataClone || []).reduce((acc, item) => {
      acc.push(item[concern.dataChartKey || 'doanh_thu']);
      return acc;
    }, []);
  }, [data, concern]);

  const labels = useMemo(() => {
    const dataClone = cloneDeep(data) || [];
    const labelKey = concern?.labelKey;
    dataClone?.pop();
    return dataClone.map((item) => item[labelKey]);
  }, [concern, data]);

  useEffect(() => {
    if (concern) {
      getReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [concern, queryObject]);

  return (
    <>
      {!mdMatches && (
        <DrawerBase
          title="Điều kiện lọc"
          anchor="left"
          zIndex={1}
          open={openDrawerFilter}
          onClose={() => setOpenDrawerFilter(false)}
        >
          <Box sx={{ width: '80vw', maxWidth: '300px' }}>{renderFilter()}</Box>
        </DrawerBase>
      )}
      <Box sx={{ padding: '10px 0' }}>
        <Grid container spacing="10px" alignItems="flex-start">
          <Grid item md={2.5}>
            {renderFilter()}
          </Grid>
          <Grid item xs={12} md={9.5}>
            <Stack gap={1}>
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>
                  {report?.title}
                </Typography>
                {concern?.showChart && (
                  <Stack direction="row" alignItems="center">
                    <Typography sx={{ fontSize: '14px' }}>
                      {isChart ? 'Dạng biểu đồ' : 'Dạng bảng'}
                    </Typography>
                    <Switch
                      checked={isChart}
                      onChange={(e) => {
                        setIsChart(e.target.checked);
                      }}
                      size="small"
                    />
                  </Stack>
                )}
              </Stack>
              {isChart && concern?.showChart ? (
                <Box
                  className="hidden-scroll"
                  sx={{
                    height:
                      'calc(100vh - 50px - 42px - 10px - 30px - 10px - 42px)',
                    overflow: 'auto',
                  }}
                >
                  <BarChart
                    titleChart={report?.title}
                    labels={labels}
                    loading={loading}
                    datasets={[
                      {
                        label: concern?.labelChart || 'doanh thu',
                        backgroundColor: theme.palette.primary.main,
                        data: doanhThus,
                      },
                    ]}
                  />
                </Box>
              ) : (
                <TableDisplay
                  columns={concern?.columns}
                  progressPending={loading}
                  data={data}
                  fixedHeaderScrollHeight="calc(100vh - 50px - 42px - 30px - 30px)"
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ReportBase;
