import React, { useEffect, useState, useMemo, useRef } from 'react';
import {
  Badge,
  Button,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { SiMicrosoftexcel } from 'react-icons/si';
import ButtonBase from '~/components/button/ButtonBase';
import { TbTableExport } from 'react-icons/tb';
import { PiTextColumns } from 'react-icons/pi';
import useApisContext from '~/hooks/hookContext/useApisContext';
import ModalImportExcel from '../modal/ModalImportExcel';
import { BsTrash } from 'react-icons/bs';
import ModalExportExcel from '../modal/ModalExportExcel';
import ModalColumns from '../modal/ModalColumns';
import ListLayout from '../layouts/ListLayout';
import { MdClose } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import MenuHover from '../menu/MenuHover';
import ChangeStatusInput from '../input/ChangeStatusInput';
import useAuthorize from '~/hooks/useAuthorize';

function ListBase({
  title,
  apiCode,
  columns,
  showColumns,
  Form,
  Filter,
  expandableRows,
  expandOnRowClicked,
  expandableRowsComponent,
  expandableRowsHideExpander,
  showStatus,
  onlyList = false,
  defaultCondition,
}) {
  const { apicode: apiCodeParam } = useParams();
  const allowAuthorize = useAuthorize();
  const { asyncSearchList } = useApisContext();
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [load, setLoad] = useState(1);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openModalColumns, setOpenModalColumns] = useState(false);
  const [showColumnsState, setShowColumnsState] = useState(showColumns);
  // const [defaultValues, setDefaultValues] = useState();
  const [toggleCleared, setToggleCleared] = useState(false);
  const [condition, setCondition] = useState({});
  const [openExcel, setOpenExcel] = useState(false);
  const [opentExportExcel, setOpenExportExcel] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [paginationOption, setPaginationOption] = useState({
    limit: 10,
    page: 1,
    totalRows: 0,
  });
  const btnDeleteRef = useRef();
  const renderCountRef = useRef(1);

  // row per page change
  const handleRowPerPageChange = (value) => {
    setPaginationOption({ ...paginationOption, page: 1, limit: value });
  };
  // handle row clicked
  const handleRowClicked = (row) => {
    // setDefaultValues(data);
    const index = data.findIndex((item) => item._id === row._id);
    setCurrentIndex(index);
    setOpenForm(true);
    setIsEdit(true);
  };
  // handle close form
  const handleCloseForm = () => {
    setOpenForm(false);
    setCurrentIndex(-1);
    // setDefaultValues(null);
  };
  // handleClearSelect
  const handleClearSelect = () => {
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };
  // export to excel
  const exportToExcel = async () => {
    setOpenExportExcel(true);
  };

  // handle delete
  const handleDelete = () => {
    btnDeleteRef?.current?.click();
  };
  // get products
  const getListData = async () => {
    if (loading) return;
    setLoading(true);
    const resp = await asyncSearchList({
      apiCode,
      condition: {
        limit: paginationOption.limit,
        page: paginationOption.page,
        q: defaultCondition || condition,
      },
    });
    const respCount = await asyncSearchList({
      apiCode,
      condition: {
        count: 1,
        q: defaultCondition || condition,
      },
    });
    if (respCount) {
      setPaginationOption({
        ...paginationOption,
        totalRows: respCount.rows_number,
      });
    }
    if (resp) {
      setData(resp);
    }
    setLoading(false);
  };

  // render delete button
  const renderActionsButton = () => {
    return (
      <>
        {selectedRows.length > 0 && (
          <Badge
            badgeContent={selectedRows.length}
            color="secondary"
            sx={{
              '& .MuiBadge-badge': {
                right: 8,
                top: 2,
                border: `2px solid #fff`,
                padding: '0 4px',
              },
            }}
          >
            <MenuHover
              content={
                <Stack sx={{ minWidth: '150px' }}>
                  {allowAuthorize({ type: 'view', module: apiCode }) && (
                    <MenuItem sx={{ columnGap: '10px' }}>
                      <TbTableExport size={14} />
                      <Typography sx={{ fontSize: '14px' }}>
                        Xuất file
                      </Typography>
                    </MenuItem>
                  )}
                  <MenuItem
                    sx={{ columnGap: '10px' }}
                    onClick={handleClearSelect}
                  >
                    <MdClose size={14} />
                    <Typography sx={{ fontSize: '14px' }}>
                      Bỏ chọn tất cả
                    </Typography>
                  </MenuItem>
                  {allowAuthorize({ type: 'delete', module: apiCode }) && (
                    <MenuItem
                      sx={{ columnGap: '10px', color: 'error.main' }}
                      onClick={handleDelete}
                    >
                      <BsTrash size={14} />
                      <Typography
                        sx={{ fontSize: '14px', color: 'error.main' }}
                      >
                        Xóa bỏ
                      </Typography>
                    </MenuItem>
                  )}
                </Stack>
              }
            >
              <Button
                variant="contained"
                sx={{
                  height: '30px',
                  textTransform: 'none',
                  color: 'whitish.pureWhite',
                }}
              >
                Thao tác
              </Button>
            </MenuHover>
          </Badge>
        )}
      </>
    );
  };
  // render button them moi
  const renderButtonAddNew = () => {
    return (
      <ButtonBase
        startIcon={<AiOutlinePlusCircle fontSize="14px" />}
        onClick={() => {
          setOpenForm(true);
          setIsEdit(false);
        }}
      >
        Thêm mới
      </ButtonBase>
    );
  };
  const renderButtonImportExcel = () => {
    return (
      <ButtonBase
        startIcon={<SiMicrosoftexcel fontSize="14px" />}
        onClick={() => setOpenExcel(true)}
      >
        Nhập excel
      </ButtonBase>
    );
  };
  const renderButtonExportExcel = () => {
    return (
      <>
        <ButtonBase
          startIcon={<TbTableExport fontSize="14px" />}
          onClick={exportToExcel}
        >
          Xuất excel
        </ButtonBase>
      </>
    );
  };
  const renderButtonColumns = () => {
    return (
      <IconButton
        sx={{
          width: '30px',
          height: '30px',
          backgroundColor: 'primary.main',
          color: 'whitish.pureWhite',
          borderRadius: '4px',
          '&:hover': {
            backgroundColor: 'primary.main',
          },
        }}
        onClick={() => setOpenModalColumns(true)}
      >
        <PiTextColumns size={14} />
      </IconButton>
    );
  };

  const currentColumns = useMemo(() => {
    if (!showColumnsState) return [];
    const result = (columns || []).reduce((acc, col, index) => {
      if ((showColumnsState || []).includes(index)) {
        return [...acc, col];
      } else {
        return acc;
      }
    }, []);
    if (showStatus) {
      result.unshift({
        name: 'Trạng thái',
        selector: (row) => row.ten_trang_thai,
        cell: (row) => (
          <ChangeStatusInput
            apiCode={apiCode}
            data={row}
            maCt={apiCode}
            setLoad={setLoad}
            {...(typeof showStatus === 'object' ? showStatus : {})}
          />
        ),
        width: '120px',
        wrap: true,
      });
    }
    return result;
  }, [columns, showColumnsState, showStatus, apiCode]);

  useEffect(() => {
    if (apiCode && renderCountRef.current > 1) {
      getListData();
      setToggleCleared(!toggleCleared);
      setSelectedRows([]);
    }
    renderCountRef.current += 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOption.limit, paginationOption.page, load]);

  useEffect(() => {
    setPaginationOption({ ...paginationOption, page: 1 });
    setLoad(load + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition]);

  useEffect(() => {
    setShowColumnsState(showColumns);
    if (apiCode) {
      setPaginationOption({ limit: 10, page: 1, totalRows: 0 });
      setLoad(load + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCode, apiCodeParam]);

  return (
    <ListLayout
      ref={btnDeleteRef}
      onlyList={onlyList}
      title={title}
      apiCode={apiCode}
      columns={currentColumns}
      data={data}
      getListData={getListData}
      handleRowClicked={handleRowClicked}
      handleRowPerPageChange={handleRowPerPageChange}
      loading={loading}
      paginationOption={paginationOption}
      expandableRows={expandableRows}
      expandOnRowClicked={expandOnRowClicked}
      expandableRowsComponent={({ data }) =>
        expandableRowsComponent?.({
          data,
          openForm: () => handleRowClicked(data),
          setLoad: setLoad,
        })
      }
      expandableRowsHideExpander={expandableRowsHideExpander}
      renderOuter={() => (
        <>
          <ModalColumns
            open={openModalColumns}
            onClose={() => setOpenModalColumns(false)}
            columns={columns}
            showColumns={showColumnsState}
            setShowColumns={setShowColumnsState}
          />
          {openForm && (
            <Form
              open={openForm}
              defaultValues={data[currentIndex]}
              isEdit={isEdit}
              currentIndex={currentIndex}
              rows={data}
              setCurrentIndex={setCurrentIndex}
              handleClose={handleCloseForm}
              setLoad={setLoad}
            />
          )}
          {openExcel && (
            <ModalImportExcel
              open={openExcel}
              handleClose={() => setOpenExcel(false)}
              apiCode={apiCode}
              setLoad={setLoad}
            />
          )}
          {opentExportExcel && (
            <ModalExportExcel
              open={opentExportExcel}
              handleClose={() => setOpenExportExcel(false)}
              apiCode={apiCode}
            />
          )}
        </>
      )}
      renderFilter={() => <Filter setCondition={setCondition} />}
      renderHeaderList={() => (
        <>
          {selectedRows.length > 0 && renderActionsButton()}
          {allowAuthorize({ type: 'add', module: apiCode }) &&
            renderButtonAddNew()}
          {allowAuthorize({ type: 'add', module: apiCode }) &&
            renderButtonImportExcel()}
          {allowAuthorize({ type: 'view', module: apiCode }) &&
            renderButtonExportExcel()}
          {renderButtonColumns()}
        </>
      )}
      setPaginationOption={setPaginationOption}
      setSelectedRows={setSelectedRows}
      setToggleCleared={setToggleCleared}
      toggleCleared={toggleCleared}
    />
  );
}

export default ListBase;
