import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import { Stack } from '@mui/material';
import { LABEL_KHO } from '~/utils/label.constant';
import { QUYEN_TRUY_CAP } from '~/utils/constants';
import { useSelector } from 'react-redux';
import InfoSection from './info-section/InfoSection';
import DescriptionSection from './description-section/DescriptionSection';
import PhanQuyenSection from './phanquyen-section/PhanQuyenSection';
import ChiTietSection from './chitiet-section/ChiTietSection';

const schema = yup.object({
  kho: yup
    .object()
    .typeError(`Vui lòng chọn ${LABEL_KHO}`)
    .required(`Vui lòng chọn ${LABEL_KHO}`),
  ngay_ct: yup
    .date()
    .typeError('Vui lòng nhập ngày chứng từ')
    .required('Vui lòng nhập ngày chứng từ'),
  khach_hang: yup
    .object()
    .typeError('Vui lòng chọn khách hàng')
    .required('Vui lòng chọn khách hàng'),
  trang_thai: yup
    .object()
    .typeError('Vui lòng chọn trạng thái')
    .required('Vui lòng chọn trạng thái'),
});

export default function FormHD2Web({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const usergroupData = useSelector((state) => state.usergroup);
  const defaultData = {
    so_ct: '',
    ma_ct: 'HD2_WEB',
    ngay_ct: moment().format('YYYY-MM-DD HH:mm'),
    kho: null,
    nhan_vien: null,
    khach_hang: null,
    pttt: null,
    trang_thai: null,
    t_tien: '',
    vat: '',
    ty_le_ck_hd: '',
    tien_ck_hd: '',
    t_ck: '',
    tien_thu2: '',
    t_tt: '',
    tien_thu: '',
    phai_tra: '',
    visible: null,
    users: [],
    usergroups: [],
  };
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        kho: defaultValues.ma_kho
          ? {
              ma_kho: defaultValues?.ma_kho,
              ten_kho: defaultValues?.ten_kho,
            }
          : null,
        nhan_vien: defaultValues.ma_nv
          ? {
              ma_nv: defaultValues?.ma_nv,
              ten_nv: defaultValues?.ten_nv,
            }
          : null,
        khach_hang: defaultValues.ma_kh
          ? {
              ma_kh: defaultValues?.ma_kh,
              ten_kh: defaultValues?.ten_kh,
            }
          : null,
        pttt: defaultValues.hinh_thuc_tt
          ? {
              _id: defaultValues?.hinh_thuc_tt,
              ten: defaultValues?.ten_hinh_thuc_tt,
            }
          : null,
        trang_thai: defaultValues.trang_thai
          ? {
              ma_trang_thai: defaultValues?.trang_thai,
              ten_trang_thai: defaultValues?.ten_trang_thai,
            }
          : null,
        ngay_ct: moment(defaultValues.ngay_ct).format('YYYY-MM-DD HH:mm'),
        visible:
          defaultValues.visible_to || defaultValues.visible_to === 0
            ? QUYEN_TRUY_CAP.find(
                (item) => item?.value === defaultValues.visible_to
              )
            : null,
        users:
          defaultValues.visible_to_users?.length > 0
            ? defaultValues.visible_to_users.map((u) => ({ email: u }))
            : [],
        usergroups: defaultValues.visible_to_usergroups
          ? defaultValues.visible_to_usergroups.map((u) => {
              return (
                (usergroupData?.data || []).find((item) => item._id === u) || {
                  group_name: u,
                }
              );
            })
          : [],
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });
  const [details, setDetails] = useState(defaultValues?.details || []);
  // const [chiphis, setChiphis] = useState(defaultValues?.ctcpmhs || []);
  // const [vats, setVats] = useState(defaultValues?.vatvaos || []);
  const [collapses, setCollapses] = useState({
    chitiet: true,
    chiphi: true,
    mota: true,
    vat: true,
    phan_quyen: true,
  });

  const tienHang = watch('t_tien_nt');
  const visible = watch('visible');

  const handleReset = () => {
    reset(originalData);
    setDetails(defaultValues?.details || []);
  };

  const generateDataPost = (values) => {
    const {
      kho,
      nhan_vien,
      khach_hang,
      kenh_ban,
      pttt,
      trang_thai,
      exfields,
      visible,
      users,
      usergroups,
      ...data
    } = values;

    const result = {
      ...data,
      exfields: { ...exfields, nhan_vien_phu_trach: true },
      ma_kho: kho?.ma_kho || '',
      ten_kho: kho?.ten_kho || '',
      ma_nv: nhan_vien?.ma_nv || '',
      ten_nv: nhan_vien?.ten_nv || '',
      ma_kh: khach_hang?.ma_kh || '',
      ten_kh: khach_hang?.ten_kh || '',
      hinh_thuc_tt: pttt?._id || '',
      ten_hinh_thuc_tt: pttt?.ten || '',
      trang_thai: trang_thai?.ma_trang_thai,
      ten_trang_thai: trang_thai?.ten_trang_thai,
      details,
      visible_to: visible?.value,
      visible_to_users: (users || []).map((u) => u.email),
      visible_to_usergroups: (usergroups || []).map((g) => g._id),
      tk_no: '1111',
      phu_trach: 'devthienthuy',
    };
    return result;
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'hd2_WEB',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="1200px"
      title="đơn hàng website"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Stack
        spacing="10px"
        sx={{
          width: '100%',
          padding: '10px',
          maxHeight: 'calc(90vh - 20px - 39px - 39px)',
          overflow: 'auto',
        }}
      >
        {/* Thong tin */}
        <InfoSection
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          isEdit={isEdit}
          tienHang={tienHang}
        />
        {/* Chi tiet */}
        <ChiTietSection
          show={collapses.chitiet}
          setCollapses={setCollapses}
          details={details}
          setDetails={setDetails}
          isEditMaster={isEdit}
        />
        {/* Mo ta */}
        <DescriptionSection
          show={collapses.mota}
          setCollapses={setCollapses}
          register={register}
        />
        {/* Phan quyen */}
        <PhanQuyenSection
          show={collapses.phan_quyen}
          setCollapses={setCollapses}
          control={control}
          visible={visible}
        />
      </Stack>
    </ModalForm>
  );
}
