import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ModalForm from '~/components/modal/ModalForm';
import * as yup from 'yup';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { Stack } from '@mui/material';
import InfoSection from './info-section/InfoSection';
import ChitietSection from './chitiet-section/ChitietSection';
import DescriptionSection from './description-section/DescriptionSection';

const schema = yup.object({
  kho: yup
    .object()
    .typeError('Vui lòng chọn kho')
    .required('Vui lòng chọn kho'),
  ngay_ct: yup
    .date()
    .typeError('Vui lòng nhập ngày chứng từ')
    .required('Vui lòng nhập ngày chứng từ'),
  trang_thai: yup
    .object()
    .typeError('Vui lòng chọn trạng thái')
    .required('Vui lòng chọn trạng thái'),
});

function FormHD2({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const defaultData = {
    so_ct: '',
    ngay_ct: moment().format('YYYY-MM-DD HH:mm'),
    kho: null,
    khach_hang: null,
    kenh_ban: null,
    trang_thai: null,
    t_tien_nt: 0,
    t_ck_nt: 0,
    t_tt_nt: 0,
  };
  const originalData = !!defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        khach_hang: defaultValues.ma_kh
          ? { ma_kh: defaultValues.ma_kh, ten_kh: defaultValues.ten_kh }
          : null,
        kho: defaultValues.ma_kho
          ? { ma_kho: defaultValues.ma_kho, ten_kho: defaultValues.ten_kho }
          : null,
        kenh_ban: defaultValues.ma_kenh
          ? {
              ma_kenh: defaultValues.ma_kenh,
              ten_kenh: defaultValues.ten_kenh,
            }
          : null,
        trang_thai: defaultValues.trang_thai
          ? {
              ma_trang_thai: defaultValues.trang_thai || '',
              ten_trang_thai: defaultValues.ten_trang_thai || '',
            }
          : null,
      }
    : defaultData;
  const {
    handleSubmit,
    reset,
    register,
    setValue,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });
  const { asyncPostData, asyncPutData } = useApisContext();
  const [details, setDetails] = useState(defaultValues?.details || []);
  const [collapses, setCollapses] = useState({ chi_tiet: true, ghi_chu: true });

  const handleReset = () => {
    reset(originalData);
    setDetails(defaultValues?.details || []);
  };

  const generateDataPost = (values) => {
    const { trang_thai, kho, khach_hang, kenh_ban, ...fields } = values;
    return {
      ...fields,
      trang_thai: trang_thai?.ma_trang_thai || '',
      ten_trang_thai: trang_thai?.ten_trang_thai || '',
      ma_kho: kho?.ma_kho || '',
      ten_kho: kho?.ten_kho || '',
      ma_kh: khach_hang?.ma_kh || '',
      ten_kh: khach_hang?.ten_kh || '',
      ma_kenh: kenh_ban?.ma_kenh || '',
      ten_kenh: kenh_ban?.ten_kenh || '',
      tk_no: 1131,
      details,
    };
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'hd2',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="1200px"
      title="hóa đơn bán hàng"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Stack spacing="20px">
        <InfoSection
          register={register}
          control={control}
          errors={errors}
          setValue={setValue}
          isEdit={isEdit}
        />
        <ChitietSection
          show={collapses.chi_tiet}
          setCollapses={setCollapses}
          details={details}
          setDetails={setDetails}
        />
        <DescriptionSection
          show={collapses.ghi_chu}
          setCollapses={setCollapses}
          register={register}
        />
      </Stack>
    </ModalForm>
  );
}

export default FormHD2;
