import React from 'react';
import CollapseSection from '~/components/collapse/CollapseSection';
import Grid from '@mui/material/Unstable_Grid2';
import { Controller } from 'react-hook-form';
import SelectInput from '~/components/input/SelectInput';
import { QUYEN_TRUY_CAP } from '~/utils/constants';
import SelectMultiApiInput from '~/components/input/SelectMultiApiInput';

function PhanQuyenSection({ show, setCollapses, control, visible }) {
  return (
    <CollapseSection
      title="Phân quyền"
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, phan_quyen: !prev.phan_quyen }))
      }
    >
      <Grid container spacing={2}>
        <Grid xs={4}>
          <Controller
            control={control}
            name="visible"
            render={({ field: { onChange, value } }) => {
              return (
                <SelectInput
                  labelWidth="30%"
                  label="Ai có thể xem"
                  placeholder="Chọn chế độ cho phép xem"
                  value={value || { label: '', value: '' }}
                  selectedValue={value}
                  onSelect={onChange}
                  options={QUYEN_TRUY_CAP.filter((item) => item.value !== 1)}
                  getOptionLabel={(option) => option.label}
                />
              );
            }}
          />
        </Grid>
        <Grid xs={8}>
          {visible?.value === 5 && (
            <Controller
              control={control}
              name="usergroups"
              render={({ field: { onChange, value } }) => (
                <SelectMultiApiInput
                  apiCode="usergroup"
                  placeholder="Chọn nhóm người dùng"
                  searchFileds={['group_name']}
                  limitTags={5}
                  getOptionLabel={(option) => option.group_name}
                  value={value}
                  onSelect={onChange}
                  isOptionEqualToValue={(option, value) => {
                    return option._id === value._id;
                  }}
                />
              )}
            />
          )}
          {visible?.value === 2 && (
            <Controller
              control={control}
              name="users"
              render={({ field: { onChange, value } }) => (
                <SelectMultiApiInput
                  apiCode="participant"
                  placeholder="Chọn người dùng"
                  searchFileds={['name', 'name']}
                  limitTags={5}
                  getOptionLabel={(option) => option.email}
                  value={value}
                  onSelect={onChange}
                  isOptionEqualToValue={(option, value) => {
                    return option.email === value.email;
                  }}
                />
              )}
            />
          )}
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default PhanQuyenSection;
