const API_URL = 'https://api135.goodapp.vn/api';
const PUBLIC_URL = 'https://api135.goodapp.vn';
// const ID_APP = '60939744ac969b4078488026'; // dev
const ID_APP = '66432d1af06bef4e9985aa6a'; // thien thuy
const PUBLIC_TOKEN = 'flex.public.token';
const KEY_GIA_GOC = 'gia_ban_le_go';
// const ID_APP = '6551aae24ddf99289bf8b219';

const LOAI_VAT_TU = [
  { ma_lvt: 'HH', ten_lvt: 'Hàng hóa thường' },
  { ma_lvt: 'DP', ten_lvt: 'Dược phẩm' },
  { ma_lvt: 'NVL', ten_lvt: 'Nguyên vật liệu' },
];
const QUYEN_TRUY_CAP = [
  { label: 'Mọi người', value: 0 },
  { label: 'Người tạo, phục trách, quản trị viên', value: 1 },
  { label: 'Người dùng chỉ định', value: 2 },
  { label: 'Nhóm người dùng chỉ định', value: 5 },
];
const THOI_GIAN_TINH = [
  {
    label: 'Ngày',
    value: 'date',
  },
  {
    label: 'Tháng',
    value: 'month',
  },
  {
    label: 'Năm',
    value: 'year',
  },
];
const KIEU_PHAN_BO = [
  {
    name: 'Phân bổ theo tiền hàng',
    value: '0',
  },
  {
    name: 'Phân bổ theo số lượng',
    value: '1',
  },
];
const LOAI_CHIET_KHAU = [
  {
    value: 1,
    label: 'Chiết khấu hóa đơn',
  },
  {
    value: 2,
    label: 'Chiết khẩu sản phẩm',
  },
];

const VIEW_RIGHT = 'view';
const VIEWOFOTHER_RIGHT = 'viewOfOther';
const ADD_RIGHT = 'add';
const UPDATE_RIGHT = 'update';
const DELETE_RIGHT = 'delete';

const ORDER_HEADER_HEIGHT = '42px';

export {
  PUBLIC_TOKEN,
  LOAI_CHIET_KHAU,
  API_URL,
  PUBLIC_URL,
  ID_APP,
  LOAI_VAT_TU,
  QUYEN_TRUY_CAP,
  ORDER_HEADER_HEIGHT,
  THOI_GIAN_TINH,
  KIEU_PHAN_BO,
  VIEW_RIGHT,
  VIEWOFOTHER_RIGHT,
  ADD_RIGHT,
  UPDATE_RIGHT,
  DELETE_RIGHT,
  KEY_GIA_GOC,
};
