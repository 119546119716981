import { AiOutlineDeploymentUnit, AiOutlineDollarCircle } from 'react-icons/ai';
import {
  BiBarChartAlt2,
  BiGift,
  BiTransferAlt,
  BiUserCheck,
} from 'react-icons/bi';
import {
  BsBoxArrowInDown,
  BsBoxSeam,
  BsBuildingAdd,
  BsCashCoin,
  BsGrid3X3,
} from 'react-icons/bs';
import {
  HiOutlineChartPie,
  HiOutlineDocumentText,
  HiOutlineInbox,
  HiOutlineUserGroup,
  HiOutlineViewGridAdd,
} from 'react-icons/hi';
import {
  HiOutlineDocumentMinus,
  HiOutlineDocumentPlus,
  HiOutlineNewspaper,
  HiOutlineRectangleGroup,
} from 'react-icons/hi2';
import { MdOutlineSell, MdTripOrigin, MdGridOn } from 'react-icons/md';
import { RiTimerFlashLine, RiUser2Line } from 'react-icons/ri';
import { TbDiscount, TbFileInvoice, TbRosette } from 'react-icons/tb';
import { GrIntegration, GrTransaction } from 'react-icons/gr';
import { IoPricetagsOutline, IoRibbonOutline } from 'react-icons/io5';
import { PiKeyReturn, PiMapPinArea, PiMapPinSimpleArea } from 'react-icons/pi';
import { SlFire } from 'react-icons/sl';
import { FaBalanceScale, FaUncharted } from 'react-icons/fa';
import { CgWebsite } from 'react-icons/cg';

const menus = [
  {
    text: 'Tổng quan',
    path: '/',
    icon: <HiOutlineViewGridAdd size={14} />,
    show: true,
  },
  {
    text: 'Hàng hóa',
    icon: <BsBoxSeam size={14} />,
    subs: [
      {
        text: 'Danh sách hàng hóa',
        icon: <BsGrid3X3 size={14} />,
        path: '/list/dmvt',
        module: 'dmvt',
      },
      {
        text: 'Nhóm hàng hóa',
        icon: <HiOutlineRectangleGroup size={14} />,
        path: '/list/dmnvt',
        module: 'dmnvt',
      },
      {
        text: 'Nhóm hoa hồng',
        icon: <TbRosette size={14} />,
        path: '/list/dmnhh',
        module: 'dmnhh',
      },
      {
        text: 'Đơn vị tính',
        icon: <AiOutlineDeploymentUnit size={14} />,
        path: '/list/dmdvt',
        module: 'dmdvt',
      },
      {
        text: 'Quy đổi đơn vị tính',
        icon: <GrIntegration size={14} />,
        path: '/list/dmqddvt',
        module: 'dmqddvt',
      },
      {
        text: 'Lô hàng hóa',
        icon: <HiOutlineInbox size={14} />,
        path: '/list/dmlo',
        module: 'dmlo',
      },
      {
        text: 'Xuất xứ',
        icon: <MdTripOrigin size={14} />,
        path: '/list/dmxuatxu',
        module: 'dmxuatxu',
      },
      {
        text: 'Tồn đầu kỳ',
        icon: <FaBalanceScale size={14} />,
        path: '/list/cdvt',
        module: 'cdvt',
      },
      {
        text: 'Kiểm kho',
        icon: <HiOutlineDocumentText size={14} />,
        path: '/list/pkk',
        module: 'pkk',
      },
    ],
  },
  {
    text: ' Giao dịch',
    icon: <BiTransferAlt size={14} />,
    subs: [
      // {
      //   text: 'Đơn hàng website',
      //   icon: <CgWebsite size={14} />,
      //   path: '/list/hd2_web',
      //   module: 'hd2_web',
      // },
      // {
      //   text: 'Trả đơn hàng website',
      //   icon: <GrTransaction size={14} />,
      //   path: '/list/hd3_web',
      //   module: 'hd3_web',
      // },
      {
        text: 'Đơn đặt hàng',
        icon: <TbFileInvoice size={14} />,
        path: '/list/hd2',
        module: 'hd2',
      },
      {
        text: 'Trả đơn đặt hàng',
        icon: <GrTransaction size={14} />,
        path: '/list/hd3',
        module: 'hd3',
      },
      {
        text: 'Phiếu mua hàng',
        icon: <HiOutlineNewspaper size={14} />,
        path: '/list/pn1',
        module: 'pn1',
      },
      {
        text: 'Trả hàng mua',
        icon: <PiKeyReturn size={14} />,
        path: '/list/pn5',
        module: 'pn5',
      },
      {
        text: 'Nhập kho nội bộ',
        icon: <BsBoxArrowInDown size={14} />,
        path: '/list/pnk',
        module: 'pnk',
      },

      {
        text: 'Xuất hủy',
        icon: <SlFire size={14} />,
        path: '/list/pxk_hanghuy',
        module: 'pxk',
      },
    ],
  },
  {
    text: 'Sổ quỹ',
    icon: <AiOutlineDollarCircle size={14} />,
    subs: [
      {
        text: 'Phiếu thu',
        icon: <HiOutlineDocumentPlus size={14} />,
        path: '/list/pt1',
        module: 'pt1',
      },
      {
        text: 'Phiếu chi',
        icon: <HiOutlineDocumentMinus size={14} />,
        path: '/list/pc1',
        module: 'pc1',
      },
      {
        text: 'Sổ quỹ',
        icon: <BsCashCoin size={14} />,
        path: '/report/soquy',
        module: 'soquy',
      },
    ],
  },
  {
    text: 'Đối tác',
    icon: <BiUserCheck size={14} />,
    subs: [
      {
        text: 'Khách hàng',
        icon: <RiUser2Line size={14} />,
        path: '/list/customer',
        module: 'customer',
      },
      {
        text: 'Nhóm khách hàng',
        icon: <HiOutlineUserGroup size={14} />,
        path: '/list/dmnhkh',
        module: 'dmnhkh',
      },
      {
        text: 'Nhà cung cấp',
        icon: <BsBuildingAdd size={14} />,
        path: '/list/dmncc',
        module: 'customer',
      },
      {
        text: 'Nhóm nhà cung cấp',
        icon: <HiOutlineUserGroup size={14} />,
        path: '/list/dmnhncc',
        module: 'dmnhncc',
      },
    ],
  },
  {
    text: 'Giá bán',
    icon: <IoPricetagsOutline size={14} />,
    subs: [
      {
        text: 'Chính sách giá bán',
        icon: <MdOutlineSell size={14} />,
        path: '/list/dmgiaban',
        module: 'dmgiaban',
      },
      {
        text: 'Khuyến mãi',
        icon: <BiGift size={14} />,
        path: '/list/dmkhuyenmai',
        module: 'dmkhuyenmai',
      },
      {
        text: 'Chiết khấu, giảm giá',
        icon: <TbDiscount size={14} />,
        path: '/list/dmchietkhau',
        module: 'dmchietkhau',
      },
      {
        text: 'E-voucher',
        icon: <IoRibbonOutline size={14} />,
        path: '/list/evoucher',
        module: 'evoucher',
      },
      {
        text: 'Flash sale',
        icon: <RiTimerFlashLine size={14} />,
        path: '/list/ecompromotion_flashsales',
        module: 'ecompromotion_flashsales',
      },
    ],
  },
  {
    text: 'Khu vực',
    icon: <PiMapPinArea size={14} />,
    subs: [
      {
        text: 'Danh sách khu vực',
        icon: <MdGridOn size={14} />,
        path: '/list/dmkhuvuc',
        module: 'dmkhuvuc',
      },
      {
        text: 'Tỉnh / thành phố',
        icon: <PiMapPinSimpleArea size={14} />,
        path: '/list/tinhthanh',
        module: 'tinhthanh',
      },
    ],
  },
  {
    text: 'Báo cáo',
    icon: <HiOutlineChartPie size={14} />,
    subs: [
      {
        text: 'Báo cáo bán hàng',
        icon: <BiBarChartAlt2 size={14} />,
        path: '/report/banhang',
        module: 'reportbanhang',
      },
      {
        text: 'Báo cáo hàng hóa',
        icon: <FaUncharted size={14} />,
        path: '/report/hanghoa',
        module: 'reporthanghoa',
      },
    ],
  },
];

const internals = [
  {
    text: 'Danh mục chức vụ',
    path: '/list/dmchucvu',
    module: 'dmchucvu',
  },
  {
    text: 'Danh mục phụ cấp',
    path: '/list/dmphucap',
    module: 'group',
  },
  {
    text: 'Quản lý nhân viên',
    path: '/list/dmnv',
    module: 'dmnv',
  },
  {
    text: 'Quản lý bộ phận',
    path: '/list/dmbp',
    module: 'dmbp',
  },
];

const systems = [
  {
    text: 'Nhóm người dùng và phân quyền',
    path: '/list/usergroup',
    module: 'usergroup',
  },
  {
    text: 'Danh sách người dùng',
    path: '/list/participant',
    module: 'participant',
  },
  {
    text: 'Tất cả chi nhánh',
    path: '/list/dmkho',
    module: 'dmkho',
  },
  {
    text: 'Phương thức thanh toán',
    path: '/list/ptthanhtoan',
    module: 'ptthanhtoan',
  },
  {
    text: 'Kênh bán hàng',
    path: '/list/dmkenhbanhang',
    module: 'dmkenhbanhang',
  },
  {
    text: 'Chi phí mua hàng',
    path: '/list/dmcpmh',
    module: 'dmcpmh',
  },
];

const websites = [
  {
    text: 'Tin tức',
    path: '/list/news2',
    module: 'news2',
  },
  {
    text: 'Loại tin tức',
    path: '/list/groupnews',
    module: 'groupnews',
  },
];

export { menus, systems, internals, websites };
