import React, { memo, useMemo } from 'react';
import { Stack } from '@mui/material';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';
import FilterContainer from '../FilterContainer';
import FilterList from '../FilterList';
import { useSelector } from 'react-redux';
import FilterRadios from '../FilterRadios';
import { LOAI_VAT_TU } from '~/utils/constants';

const loaiVatTus = LOAI_VAT_TU.reduce((acc, item) => {
  return [...acc, { label: item.ten_lvt, value: item.ma_lvt }];
}, []);

const typeValues = [
  {
    label: 'Hàng HOT',
    value: 'hot',
  },
  {
    label: 'Hàng nổi',
    value: 'banner_small',
  },
  {
    label: 'Bán chạy',
    value: 'bestseller',
  },
  {
    label: 'Hàng mới',
    value: 'newproduct',
  },
  {
    label: 'Hàng giảm giá',
    value: 'ty_le_ck0',
    expr: { $gt: 0 },
  },
  {
    label: 'Có hóa đơn',
    value: 'exfields.hoa_don',
  },
];

function FilterProduct({ setCondition }) {
  const originFilter = {
    vat_tu: '',
    loai_vat_tu: [],
    nhom_vat_tu: [],
    type: [],
    ton_kho: 'all',
    trang_thai: 'true',
  };
  const { data: productGroups } = useSelector((state) => state.productGroup);
  const [mode, setMode] = useState(1);
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  const groups = useMemo(() => {
    if (!productGroups || !Array.isArray(productGroups)) return [];
    return (productGroups || []).reduce((acc, group) => {
      return [...acc, { label: group.ten_nvt, value: group._id }];
    }, []);
  }, [productGroups]);

  useEffect(() => {
    let condition = {};
    if (filter.vat_tu) {
      condition.$or = [
        {
          ma_vt: {
            $regex: filter.vat_tu.split(' ').join('.*'),
            $options: 'i',
          },
        },
        {
          ten_vt: {
            $regex: filter.vat_tu.split(' ').join('.*'),
            $options: 'i',
          },
        },
      ];
    }
    if ((filter.loai_vat_tu || []).length > 0) {
      condition.ma_lvt = { $in: filter.loai_vat_tu };
    }
    if ((filter.nhom_vat_tu || []).length > 0) {
      condition.ma_nvt = { $in: filter.nhom_vat_tu };
    }
    if (filter.type.length > 0) {
      filter.type.forEach((item) => {
        const currentItem = typeValues.find((t) => t.value === item);
        if (currentItem.expr) {
          condition[item] = currentItem.expr;
        } else {
          condition[item] = true;
        }
      });
    }
    // switch (filter.ton_kho) {
    //   case 'over':
    //     condition.$expr = { $gt: ['$ton00', '$ton_toi_da'] };
    //     break;
    //   case 'under':
    //     condition.$expr = { $lt: ['$ton00', '$ton_toi_thieu'] };
    //     break;
    //   default: // all
    //     break;
    // }
    switch (filter.trang_thai) {
      case 'true':
        condition.status = true;
        break;
      case 'false':
        condition.status = false;
        break;
      default: // all
        condition.status = { $in: [true, false] };
        break;
    }
    setCondition(condition);
    if (mode === 1) {
      setCondition(condition);
    } else {
      const entries = Object.entries(condition);
      condition = entries.reduce(
        (acc, item) => {
          acc.$or.push({ [item[0]]: item[1] });
          return acc;
        },
        { $or: [] }
      );
      setCondition(condition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, mode]);

  return (
    <FilterContainer
      mode={mode}
      setMode={setMode}
      handleRefreshFilter={handleRefreshFilter}
    >
      <Stack sx={{ width: '100%' }} spacing="10px">
        <FilterSearch
          title="Mã, tên hàng hóa"
          placeholder="Tìm theo mã hoặc tên"
          value={filter.vat_tu}
          onSearch={(value) => setFilter({ ...filter, vat_tu: value })}
        />
        <FilterList
          title="Loại hàng"
          items={loaiVatTus}
          defaultValues={filter.loai_vat_tu}
          onChange={(value) => setFilter({ ...filter, loai_vat_tu: value })}
        />
        <FilterList
          title="Nhóm hàng"
          items={groups}
          defaultValues={filter.nhom_vat_tu}
          onChange={(value) => setFilter({ ...filter, nhom_vat_tu: value })}
        />
        <FilterList
          title="Phân loại"
          items={typeValues}
          defaultValues={filter.type}
          onChange={(value) => setFilter({ ...filter, type: value })}
        />
        {/* <FilterRadios
          title="Tồn kho"
          values={[
            { label: 'Tất cả', value: 'all' },
            { label: 'Vượt định mức tồn', value: 'over' },
            { label: 'Dưới định mức tồn', value: 'under' },
          ]}
          defaultValue={filter.ton_kho}
          onChange={(value) => setFilter({ ...filter, ton_kho: value })}
        /> */}
        <FilterRadios
          title="Trạng thái"
          values={[
            { label: 'Tất cả', value: 'all' },
            { label: 'Đang kinh doanh', value: 'true' },
            { label: 'Ngừng kinh doanh', value: 'false' },
          ]}
          defaultValue={filter.trang_thai}
          onChange={(value) => setFilter({ ...filter, trang_thai: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default memo(FilterProduct);
