import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import InfoSection from './info-section/InfoSection';
import ChamCongSection from './chamcong-section/ChamCongSection';
import PhuCapSection from './phucap-section/PhuCapSection';
import ThueTNCNSection from './thuetncn-section/ThueTNCNSection';
import NganHangSection from './nganhang-section/NganHangSection';

const schema = yup.object({
  ten_nv: yup.string().required('Vui lòng nhập tên nhân viên'),
  dien_thoai: yup
    .string()
    .required('Vui lòng nhập số điện thoại')
    .matches(/^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/, {
      message: 'Số điện thoại không hợp lệ',
      excludeEmptyString: true,
    }),
  email: yup.string().email('Email không đúng định dạng'),
});

const defaultData = {
  // general info
  ma_nv: '',
  ten_nv: '',
  dien_thoai: '',
  ngay_sinh: null,
  email: '',
  dia_chi: '',
  bo_phan: null,
  chuc_vu: null,
  device_user_id: '',
  account: null,
  // cham cong va luong
  ngay_bat_dau_lam: null,
  ngay_nghi_viec: null,
  thu_viec_tu_ngay: null,
  thu_viec_den_ngay: null,
  ngay_tinh_bhxh: null,
  luong_thu_viec: 0,
  luong_co_ban: 0,
  luong_bhxh: 0,
  lam_viec: true,
  cham_cong: true,
  tinh_luong: true,
  bhxh: true,
  bhtn: true,
  bhyt: true,
  // thue thu nhap ca nhan
  ttncn_mst: '',
  ttncn_so_nguoi_phu_thuoc: 0,
  ttncn_muc_gt_phu_thuoc: 0,
  ttncn_so_thang_gt_ban_than: 0,
  ttncn_muc_gt_ban_than: 0,
  // ngan hang
  so_tk_ngan_hang: '',
  ten_chu_tk_ngan_hang: '',
  phat_hanh_boi_ngan_hang: '',
};

export default function FormNhanVien({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        chuc_vu: defaultValues.chuc_vu
          ? {
              _id: defaultValues.chuc_vu,
              group_name: defaultValues.ten_chuc_vu,
            }
          : null,
        bo_phan: defaultValues.ma_bp
          ? { ma_bp: defaultValues.ma_bp, ten_bp: defaultValues.ten_bp }
          : null,
        account: defaultValues.user ? { email: defaultValues.user } : null,
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    register,
    control,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });
  const [phucaps, setPhucaps] = useState(defaultValues?.phu_cap || []);
  const [collapses, setCollapses] = useState({
    cham_cong: true,
    phu_cap: true,
    thue: true,
    ngan_hang: true,
    account: true,
  });

  const handleReset = () => {
    reset(originalData);
    setPhucaps(defaultValues?.phu_cap || []);
  };

  const generateDataPost = (values) => {
    const { bo_phan, chuc_vu, account, ...fields } = values;
    return {
      ...fields,
      ma_bp: bo_phan?.ma_bp || '',
      ten_bp: bo_phan?.ten_bp || '',
      chuc_vu: chuc_vu?._id || '',
      ten_chuc_vu: chuc_vu?.group_name || '',
      user: account?.email || '',
      phu_cap: phucaps,
    };
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'dmnv',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="800px"
      title="nhân viên"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Stack spacing="20px" sx={{ width: '100%' }}>
        <InfoSection
          register={register}
          errors={errors}
          control={control}
          isEdit={isEdit}
        />
        <ChamCongSection
          show={collapses.cham_cong}
          setCollapses={setCollapses}
          control={control}
          errors={errors}
        />
        <PhuCapSection
          show={collapses.phu_cap}
          setCollapses={setCollapses}
          details={phucaps}
          setDetails={setPhucaps}
        />
        <ThueTNCNSection
          show={collapses.thue}
          setCollapses={setCollapses}
          register={register}
          control={control}
          errors={errors}
        />
        <NganHangSection
          show={collapses.ngan_hang}
          setCollapses={setCollapses}
          register={register}
        />
      </Stack>
    </ModalForm>
  );
}
