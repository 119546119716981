import React from 'react';
import DataTable from 'react-data-table-component';
import { useTheme } from '@mui/material/styles';
import { Box, CircularProgress, Typography } from '@mui/material';

function TableDisplay({
  columns,
  data,
  pagination,
  paginationTotalRows,
  paginationPerPage,
  onChangePage = () => {},
  onChangeRowsPerPage = () => {},
  title,
  selectableRows,
  progressPending = false,
  onRowClicked,
  onSelectedRowsChange,
  clearSelectedRows,
  fixedHeaderScrollHeight = '300px',
}) {
  const theme = useTheme();

  return (
    <DataTable
      persistTableHead
      fixedHeader
      fixedHeaderScrollHeight={fixedHeaderScrollHeight}
      pointerOnHover
      highlightOnHover
      onRowClicked={onRowClicked}
      columns={columns}
      data={data}
      selectableRows={selectableRows}
      onSelectedRowsChange={onSelectedRowsChange}
      clearSelectedRows={clearSelectedRows}
      // pagination start
      pagination={pagination}
      paginationTotalRows={paginationTotalRows}
      paginationPerPage={paginationPerPage}
      paginationDefaultPage={1}
      paginationRowsPerPageOptions={[20, 50, 100, 500]}
      paginationComponentOptions={{
        rowsPerPageText: 'Dòng trên bảng',
        rangeSeparatorText: 'trên',
      }}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      // pagination end
      // process start
      progressPending={progressPending}
      progressComponent={
        <Box
          sx={{
            width: '100%',
            height: '100px',
            paddingTop: '20px',
            textAlign: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      }
      // process end
      noDataComponent={
        <Typography
          sx={{ fontSize: '14px', textAlign: 'center', padding: '20px 0' }}
        >
          {title ? `Không có ${title}` : 'Không có dữ liệu'}
        </Typography>
      }
      customStyles={{
        headRow: {
          style: {
            minHeight: '32px',
            fontWeight: 600,
            borderBottom: 'none',
            borderRadius: '6px 6px 0 0',
            overflow: 'hidden',
          },
        },
        headCells: {
          style: {
            backgroundColor: theme.palette.primary.second,
            color: theme.palette.whitish.pureWhite,
            paddingLeft: '8px',
            paddingRight: '8px',
            '& input': {
              accentColor: theme.palette.whitish.pureWhite,
            },
          },
        },
        rows: {
          style: {
            backgroundColor: theme.palette.primary.opacity,
          },
          highlightOnHoverStyle: {
            backgroundColor: theme.palette.primary.opacity,
          },
          selectedHighlightStyle: {
            backgroundColor: `${theme.palette.primary.opacity} !important`,
          },
        },
        cells: {
          style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            '& input': {
              accentColor: theme.palette.primary.main,
            },
          },
        },
      }}
    />
  );
}

export default TableDisplay;
