import React, { useEffect, useState } from 'react';
import { formatDateDisplay } from '~/utils/helpers';
import { Stack } from '@mui/material';
import TableDisplay from '~/components/table/TableDisplay';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import CollapseSection from '~/components/collapse/CollapseSection';

const columns = [
  {
    name: 'Số lô',
    selector: (row) => row.ma_lo,
  },
  {
    name: 'Hạn sử dụng',
    selector: (row) => row.han_sd,
    format: (row) => formatDateDisplay(row.han_sd),
    center: true,
  },
  {
    name: 'Số lượng tồn',
    selector: (row) => row.ton_kho,
    wrap: true,
    center: true,
  },
  {
    name: 'Đơn vị tính',
    selector: (row) => row.ten_dvt,
    wrap: true,
    right: true,
  },
];

function LoSection({ maVt, tenDvt, show, setCollapses }) {
  const { asyncSearchList } = useApisContext();
  const showAlert = useAlertContext();
  const [los, setLos] = useState([]);
  const [loading, setLoading] = useState(false);

  const getLos = async () => {
    try {
      setLoading(true);
      const respLo = asyncSearchList({
        apiCode: 'dmlo',
        condition: {
          page: 1,
          limit: 99999,
          q: {
            status: true,
            ma_vt: maVt,
          },
        },
      });
      const respTonKho = asyncSearchList({
        apiCode: 'tontucthoi',
        condition: { page: 1, limit: 999999, q: { ma_vt: maVt } },
      });
      Promise.all([respLo, respTonKho]).then(([los, tonKhos]) => {
        const result = (los || []).reduce((acc, item) => {
          const tonKho = (tonKhos || []).find((t) => t.ma_lo === item.ma_lo);
          return [
            ...acc,
            {
              ma_lo: item.ma_lo,
              han_sd: item.han_sd,
              ton_kho: tonKho?.ton00 || 0,
              ten_dvt: tenDvt,
            },
          ];
        }, []);
        setLos(result);
      });
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.response?.data.message || 'Something went wrong!',
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getLos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maVt]);

  return (
    <CollapseSection
      title="Lô hàng hóa"
      show={show}
      onCollapse={() => setCollapses((prev) => ({ ...prev, lo: !prev.lo }))}
    >
      <Stack sx={{ width: '100%' }} spacing="10px">
        <TableDisplay
          data={los}
          columns={columns}
          title="lô"
          progressPending={loading}
        />
      </Stack>
    </CollapseSection>
  );
}

export default LoSection;
