import React, { useState, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import FilterRadios from '../FilterRadios';
import FilterSelectApi from '../FilterSelectApi';
import FilterTimeFromTo from '../FilterTimeFromTo';
import moment from 'moment';
import { formatDateDisplay, groupBy, numeralCustom } from '~/utils/helpers';
import { TbRuler2Off } from 'react-icons/tb';
import { cloneDeep } from 'lodash';
import { LABEL_KHO } from '~/utils/label.constant';

const concerns = [
  {
    value: 'thoigian',
    label: 'Thời gian',
  },
  {
    value: 'dtbanletheokho',
    label: LABEL_KHO,
    api: 'ctbanle',
    labelKey: 'ten_kho',
    showChart: true,
    convertData: (data) => {
      const result = [];
      const total = data.pop();
      const dataGroup = groupBy({ data, callbackMatch: (item) => item.ma_kho });

      dataGroup.forEach((group) => {
        const res = group.reduce(
          (acc, item) => {
            return {
              ma_kho: item.ma_kho,
              ten_kho: item.ten_kho,
              tien_hang: acc.tien_hang + item.tien_hang_nt,
              tien_ck: acc.tien_ck + item.tien_ck_nt,
              doanh_thu: acc.doanh_thu + item.tien_nt,
            };
          },
          {
            ma_kho: '',
            ten_kho: '',
            tien_hang: 0,
            tien_ck: 0,
            doanh_thu: 0,
          }
        );
        result.push(res);
      });

      result.push({
        ten_kho: 'Tổng cộng',
        tien_hang: total.tien_hang,
        tien_ck: total.tien_ck,
        doanh_thu: total.tien,
      });
      return result;
    },
    columns: [
      {
        name: 'Mã kho',
        selector: (row) => row.ma_kho,
        width: '100px',
        wrap: true,
      },
      {
        name: 'Tên kho',
        selector: (row) => row.ten_kho,
      },
      {
        name: 'Tiền hàng',
        selector: (row) => row.tien_hang,
        format: (row) => numeralCustom(row.tien_hang).format(),
      },
      {
        name: 'Tiền chiết khấu',
        selector: (row) => row.tien_ck,
        format: (row) => numeralCustom(row.tien_ck).format(),
        center: true,
      },
      {
        name: 'Doanh thu',
        selector: (row) => row.doanh_thu,
        format: (row) => numeralCustom(row.doanh_thu).format(),
        right: true,
      },
    ],
  },
  {
    value: 'dtbanletheokh',
    api: 'ctbanle',
    label: 'Khách hàng',
    labelKey: 'ten_kh',
    showChart: true,
    convertData: (data) => {
      const result = [];
      const total = data.pop();
      const dataGroup = groupBy({ data, callbackMatch: (item) => item.ma_kh });
      dataGroup.forEach((group) => {
        const res = group.reduce(
          (acc, item) => {
            return {
              ma_kh: item.ma_kh,
              ten_kh: item.ten_kh || 'Khách lẻ',
              tien_hang: acc.tien_hang + item.tien_hang_nt,
              tien_ck: acc.tien_ck + item.tien_ck_nt,
              doanh_thu: acc.doanh_thu + item.tien_nt,
            };
          },
          {
            ma_kh: '',
            ten_kh: '',
            tien_hang: 0,
            tien_ck: 0,
            doanh_thu: 0,
          }
        );
        result.push(res);
      });
      result.push({
        ma_kh: '',
        ten_kh: 'Tổng cộng',
        tien_hang: total.tien_hang,
        tien_ck: total.tien_ck,
        doanh_thu: total.tien,
      });
      return result;
    },
    columns: [
      {
        name: 'Mã khách hàng',
        selector: (row) => row.ma_kh,
        width: '150px',
        wrap: true,
      },
      {
        name: 'Tên khách hàng',
        selector: (row) => row.ten_kh,
      },
      {
        name: 'Tiền hàng',
        selector: (row) => row.tien_hang,
        format: (row) => numeralCustom(row.tien_hang).format(),
        center: true,
      },
      {
        name: 'Tiền chiết khấu',
        selector: (row) => row.tien_ck,
        format: (row) => numeralCustom(row.tien_ck).format(),
        center: true,
      },
      {
        name: 'Doanh thu',
        selector: (row) => row.doanh_thu,
        format: (row) => numeralCustom(row.doanh_thu).format(),
        right: true,
      },
    ],
  },
  {
    value: 'dtbanletheonv',
    api: 'ctbanle',
    label: 'Nhân viên',
    labelKey: 'ten_nv',
    showChart: true,
    convertData: (data) => {
      const result = [];
      const total = data.pop();
      const dataGroup = groupBy({ data, callbackMatch: (item) => item.ma_nv });
      dataGroup.forEach((group) => {
        const res = group.reduce(
          (acc, item) => {
            return {
              ma_nv: item.ma_nv || 'Chưa xác định',
              ten_nv: item.ten_nv || 'Chưa xác định',
              tien_hang: acc.tien_hang + item.tien_hang,
              tien_ck: acc.tien_ck + item.tien_ck,
              doanh_thu: acc.doanh_thu + item.tien_xuat,
            };
          },
          {
            ma_v: '',
            ten_nv: '',
            tien_hang: 0,
            tien_ck: 0,
            doanh_thu: 0,
          }
        );
        result.push(res);
      });
      result.unshift({
        ma_nv: 'Tổng cộng',
        ten_nv: 'Tất cả nhân viên',
        tien_hang: total.tien_hang,
        tien_ck: total.tien_ck,
        doanh_thu: total.tien_xuat,
      });
      return result;
    },
    columns: [
      {
        name: 'Mã nhân viên',
        selector: (row) => row.ma_nv,
        cell: (row, index) => {
          if (index === 0) {
            return (
              <Typography sx={{ fontWeight: 600 }}>{row.ma_nv}</Typography>
            );
          }
          return row.ma_nv;
        },
        width: '150px',
        wrap: true,
      },
      {
        name: 'Tên nhân viên',
        selector: (row) => row.ten_nv,
        cell: (row, index) => {
          if (index === 0) {
            return (
              <Typography sx={{ fontWeight: 600 }}>{row.ten_nv}</Typography>
            );
          }
          return row.ten_nv;
        },
        wrap: true,
      },
      {
        name: 'Tiền hàng',
        selector: (row) => row.tien_hang,
        format: (row) => numeralCustom(row.tien_hang).format(),
        cell: (row, index) => {
          if (index === 0) {
            return (
              <Typography sx={{ fontWeight: 600 }}>
                {numeralCustom(row.tien_hang).format()}
              </Typography>
            );
          }
          return numeralCustom(row.tien_hang).format();
        },
        center: true,
        wrap: true,
      },
      {
        name: 'Tiền chiết khấu',
        selector: (row) => row.tien_ck,
        format: (row) => numeralCustom(row.tien_ck).format(),
        cell: (row, index) => {
          if (index === 0) {
            return (
              <Typography sx={{ fontWeight: 600 }}>
                {numeralCustom(row.tien_ck).format()}
              </Typography>
            );
          }
          return numeralCustom(row.tien_ck).format();
        },
        center: true,
        wrap: true,
      },
      {
        name: 'Doanh thu',
        selector: (row) => row.doanh_thu,
        format: (row) => numeralCustom(row.doanh_thu).format(),
        cell: (row, index) => {
          if (index === 0) {
            return (
              <Typography sx={{ fontWeight: 600 }}>
                {numeralCustom(row.doanh_thu).format()}
              </Typography>
            );
          }
          return numeralCustom(row.doanh_thu).format();
        },
        right: true,
        wrap: true,
      },
    ],
  },
  {
    value: 'hdgiamgia',
    api: 'ctbanle',
    label: 'Hóa đơn giảm giá',
    labelKey: 'thoi_gian',
    dataChartKey: 'giam_gia',
    labelChart: 'Giảm giá',
    showChart: true,
    convertData: (data) => {
      const result = [];
      data.pop();
      const dataGroup = groupBy({
        data,
        callbackMatch: (item) => item.ngay_ct,
      });
      dataGroup.forEach((group) => {
        const res = group.reduce(
          (acc, item) => {
            if (item.tien_ck_nt > 0) {
              return {
                thoi_gian: formatDateDisplay(item.ngay_ct),
                sl_hd: acc.sl_hd + 1,
                gia_tri: acc.gia_tri + item.tien_hang_nt,
                giam_gia: acc.giam_gia + item.tien_ck_nt,
              };
            } else {
              return acc;
            }
          },
          {
            thoi_gian: '',
            sl_hd: 0,
            gia_tri: 0,
            giam_gia: 0,
          }
        );
        if (res.thoi_gian) {
          result.push(res);
        }
      });
      if (result.length > 0) {
        const total = result.reduce(
          (acc, item) => {
            return {
              sl_hd: acc.sl_hd + item.sl_hd,
              gia_tri: acc.gia_tri + item.gia_tri,
              giam_gia: acc.giam_gia + item.giam_gia,
            };
          },
          { sl_hd: 0, gia_tri: 0, giam_gia: 0 }
        );
        result.push(total);
      }
      return result;
    },
    columns: [
      {
        name: 'Thời gian',
        selector: (row) => row.thoi_gian,
        width: '150px',
        wrap: true,
      },
      {
        name: 'Tổng số lượng',
        selector: (row) => row.sl_hd,
        center: true,
      },
      {
        name: 'Giá trị',
        selector: (row) => row.gia_tri,
        format: (row) => numeralCustom(row.gia_tri).format(),
        center: true,
      },
      {
        name: 'Giảm giá',
        selector: (row) => row.giam_gia,
        format: (row) => numeralCustom(row.giam_gia).format(),
        right: true,
      },
    ],
  },
  {
    value: 'trahang',
    api: 'ctbanle',
    label: 'Trả hàng',
    labelKey: 'thoi_gian',
    dataChartKey: 'gia_tri_tra',
    labelChart: 'Giá trị tr',
    showChart: true,
    convertData: (data) => {
      const result = [];
      data.pop();
      const dataGroup = groupBy({
        data,
        callbackMatch: (item) => item.ngay_ct,
      });
      dataGroup.forEach((group) => {
        const res = group.reduce(
          (acc, item) => {
            if (item.tien_tl > 0) {
              return {
                thoi_gian: formatDateDisplay(item.ngay_ct),
                sl_hd: acc.sl_hd + 1,
                gia_tri: acc.gia_tri_tl + item.tien_tl,
              };
            } else {
              return acc;
            }
          },
          {
            thoi_gian: '',
            sl_hd: 0,
            gia_tri_tl: 0,
          }
        );
        if (res.thoi_gian) {
          result.push(res);
        }
      });
      if (result.length > 0) {
        const total = result.reduce(
          (acc, item) => {
            return {
              sl_hd: acc.sl_hd + item.sl_hd,
              gia_tri_tl: acc.gia_tri_tl + item.gia_tri_tl,
            };
          },
          { sl_hd: 0, gia_tri: 0 }
        );
        result.push(total);
      }
      return result;
    },
    columns: [
      {
        name: 'Thời gian',
        selector: (row) => row.thoi_gian,
        width: '150px',
        wrap: true,
      },
      {
        name: 'Tổng số lượng',
        selector: (row) => row.sl_hd,
        center: true,
      },
      {
        name: 'Giá trị trả lại',
        selector: (row) => row.gia_tri_tl,
        format: (row) => numeralCustom(row.gia_tri_tl).format(),
        rith: true,
      },
    ],
  },
];
const timeConcerns = {
  fromTo: {
    api: 'ctbanle',
    labelKey: 'ngay_ct',
    convertData: (data) => {
      const total = data.pop();
      return [
        {
          ngay_ct: 'Tổng cổng',
          tien_hang: total.tien_hang,
          tien_ck: total.tien_ck,
          doanh_thu: total.tien,
        },
      ];
    },
    columns: [
      {
        name: 'Ngày',
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct),
        width: '100px',
      },
      {
        name: 'Tiền hàng',
        selector: (row) => row.tien_hang,
        format: (row) => numeralCustom(row.tien_hang).format(),
        center: true,
      },
      {
        name: 'Tiền chiết khấu',
        selector: (row) => row.tien_ck,
        format: (row) => numeralCustom(row.tien_ck).format(),
        center: TbRuler2Off,
      },
      {
        name: 'Doanh thu',
        selector: (row) => row.doanh_thu,
        format: (row) => numeralCustom(row.doanh_thu).format(),
        right: true,
      },
    ],
  },
  today: {
    api: 'ctbanle',
    labelKey: 'ngay_ct',
    convertData: (data) => {
      if (data.length === 0) {
        return [];
      }
      const record = data.pop();
      return [
        {
          ngay_ct: formatDateDisplay((data[0] || record).ngay_ct),
          tien_hang: record.tien_hang,
          doanh_thu: record.tien,
          tien_ck: record.tien_ck,
        },
      ];
    },
    columns: [
      {
        name: 'Ngày',
        selector: (row) => row.ngay_ct,
        width: '120px',
      },
      {
        name: 'Tiền hàng',
        selector: (row) => row.tien_hang,
        format: (row) => numeralCustom(row.tien_hang).format(),
        center: true,
      },
      {
        name: 'Tiền chiết khấu',
        selector: (row) => row.tien_ck,
        format: (row) => numeralCustom(row.tien_ck).format(),
        center: TbRuler2Off,
      },
      {
        name: 'Doanh thu',
        selector: (row) => row.doanh_thu,
        format: (row) => numeralCustom(row.doanh_thu).format(),
        right: true,
      },
    ],
  },
  thisweek: {
    api: 'ctbanle',
    labelKey: 'ngay_ct',
    showChart: true,
    convertData: (data) => {
      const total = data.pop();
      const result = [];
      const dataGroup = groupBy({
        data,
        callbackMatch: (item) => {
          return moment(item.ngay_ct).format('DD/MM/YYYY');
        },
      });
      dataGroup.forEach((group) => {
        const res = group.reduce(
          (acc, item) => {
            return {
              ngay_ct: formatDateDisplay(item.ngay_ct),
              tien_hang: acc.tien_hang + item.tien_hang,
              tien_ck: acc.tien_ck + item.tien_ck,
              doanh_thu: acc.doanh_thu + item.tien,
            };
          },
          {
            ngay_ct: '',
            tien_hang: 0,
            tien_ck: 0,
            doanh_thu: 0,
          }
        );
        result.push(res);
      });
      result.push({
        ngay_ct: 'Tổng cộng',
        tien_hang: total.tien_hang,
        tien_ck: total.tien_ck,
        doanh_thu: total.tien,
      });
      return result;
    },
    columns: [
      {
        name: 'Ngày',
        selector: (row) => row.ngay_ct,
        width: '120px',
      },
      {
        name: 'Tiền hàng',
        selector: (row) => row.tien_hang,
        format: (row) => numeralCustom(row.tien_hang).format(),
        center: true,
      },
      {
        name: 'Tiền chiết khấu',
        selector: (row) => row.tien_ck,
        format: (row) => numeralCustom(row.tien_ck).format(),
        center: TbRuler2Off,
      },
      {
        name: 'Doanh thu',
        selector: (row) => row.doanh_thu,
        format: (row) => numeralCustom(row.doanh_thu).format(),
        right: true,
      },
    ],
  },
  thismonth: {
    api: 'ctbanle',
    labelKey: 'thoi_gian',
    convertData: (data) => {
      const total = data.pop();
      const today = moment().toDate();
      const result = [
        {
          thoi_gian: `Tháng ${
            today.getUTCMonth() + 1
          }/${today.getUTCFullYear()}`,
          tien_hang: total.tien_hang,
          tien_ck: total.tien_ck,
          doanh_thu: total.tien,
        },
      ];
      return result;
    },
    columns: [
      {
        name: 'Thời gian',
        selector: (row) => row.thoi_gian,
        width: '120px',
      },
      {
        name: 'Tiền hàng',
        selector: (row) => row.tien_hang,
        format: (row) => numeralCustom(row.tien_hang).format(),
        center: true,
      },
      {
        name: 'Tiền chiết khấu',
        selector: (row) => row.tien_ck,
        format: (row) => numeralCustom(row.tien_ck).format(),
        center: TbRuler2Off,
      },
      {
        name: 'Doanh thu',
        selector: (row) => row.doanh_thu,
        format: (row) => numeralCustom(row.doanh_thu).format(),
        right: true,
      },
    ],
  },
  lastmonth: {
    api: 'ctbanle',
    labelKey: 'thoi_gian',
    convertData: (data) => {
      const total = data.pop();
      const today = moment().subtract(1, 'months').toDate();
      const result = [
        {
          thoi_gian: `Tháng ${
            today.getUTCMonth() + 1
          }/${today.getUTCFullYear()}`,
          tien_hang: total.tien_hang,
          tien_ck: total.tien_ck,
          doanh_thu: total.tien,
        },
      ];
      return result;
    },
    columns: [
      {
        name: 'Thời gian',
        selector: (row) => row.thoi_gian,
        width: '120px',
      },
      {
        name: 'Tiền hàng',
        selector: (row) => row.tien_hang,
        format: (row) => numeralCustom(row.tien_hang).format(),
        center: true,
      },
      {
        name: 'Tiền chiết khấu',
        selector: (row) => row.tien_ck,
        format: (row) => numeralCustom(row.tien_ck).format(),
        center: TbRuler2Off,
      },
      {
        name: 'Doanh thu',
        selector: (row) => row.doanh_thu,
        format: (row) => numeralCustom(row.doanh_thu).format(),
        right: true,
      },
    ],
  },
  '30daysago': {
    api: 'ctbanle',
    labelKey: 'thoi_gian',
    showChart: true,
    convertData: (data) => {
      const result = [];
      const total = data.pop();
      const dataGroup = groupBy({
        data,
        callbackMatch: (item) => `${item.nam}${item.thang}`,
      });
      dataGroup.forEach((group) => {
        const res = group.reduce(
          (acc, item) => {
            return {
              thoi_gian: `Tháng ${item.thang}/${item.nam}`,
              tien_hang: acc.tien_hang + item.tien_hang_nt,
              tien_ck: acc.tien_ck + item.tien_ck_nt,
              doanh_thu: acc.doanh_thu + item.tien_nt,
            };
          },
          {
            thoi_gian: '',
            tien_hang: 0,
            tien_ck: 0,
            doanh_thu: 0,
          }
        );
        result.push(res);
      });

      result.push({
        thoi_gian: `Tổng cộng`,
        tien_hang: total.tien_hang,
        tien_ck: total.tien_ck,
        doanh_thu: total.tien,
      });
      return result;
    },
    columns: [
      {
        name: 'Thời gian',
        selector: (row) => row.thoi_gian,
        width: '120px',
      },
      {
        name: 'Tiền hàng',
        selector: (row) => row.tien_hang,
        format: (row) => numeralCustom(row.tien_hang).format(),
        center: true,
      },
      {
        name: 'Tiền chiết khấu',
        selector: (row) => row.tien_ck,
        format: (row) => numeralCustom(row.tien_ck).format(),
        center: TbRuler2Off,
      },
      {
        name: 'Doanh thu',
        selector: (row) => row.doanh_thu,
        format: (row) => numeralCustom(row.doanh_thu).format(),
        right: true,
      },
    ],
  },
  thisquarter: {
    api: 'ctbanle',
    labelKey: 'thoi_gian',
    showChart: true,
    convertData: (data) => {
      const result = [];
      const total = data.pop();

      const dataGroup = groupBy({
        data,
        callbackMatch: (item) => {
          return `${item.thang}/${item.nam}`;
        },
      });
      dataGroup.forEach((group) => {
        const res = group.reduce(
          (acc, item) => {
            return {
              thoi_gian: `Tháng ${item.thang}/${item.nam}`,
              tien_hang: acc.tien_hang + item.tien_hang_nt,
              tien_ck: acc.tien_ck + item.tien_ck_nt,
              doanh_thu: acc.doanh_thu + item.tien_nt,
            };
          },
          { thoi_gian: '', tien_hang: 0, tien_ck: 0, doanh_thu: 0 }
        );
        result.push(res);
      });

      result.push({
        thoi_gian: 'Tổng cộng',
        tien_hang: total.tien_hang,
        tien_ck: total.tien_ck,
        doanh_thu: total.tien,
      });
      return result;
    },
    columns: [
      {
        name: 'Thời gian',
        selector: (row) => row.thoi_gian,
        width: '120px',
      },
      {
        name: 'Tiền hàng',
        selector: (row) => row.tien_hang,
        format: (row) => numeralCustom(row.tien_hang).format(),
        center: true,
      },
      {
        name: 'Tiền chiết khấu',
        selector: (row) => row.tien_ck,
        format: (row) => numeralCustom(row.tien_ck).format(),
        center: TbRuler2Off,
      },
      {
        name: 'Doanh thu',
        selector: (row) => row.doanh_thu,
        format: (row) => numeralCustom(row.doanh_thu).format(),
        right: true,
      },
    ],
  },
  thisyear: {
    api: 'ctbanle',
    labelKey: 'thoi_gian',
    showChart: true,
    convertData: (data) => {
      const result = [];
      const total = data.pop();

      const dataGroup = groupBy({
        data,
        callbackMatch: (item) => {
          return `${item.quy}/${item.nam}`;
        },
      });
      dataGroup.forEach((group) => {
        const res = group.reduce(
          (acc, item) => {
            return {
              thoi_gian: `Quý ${item.quy} năm ${item.nam}`,
              tien_hang: acc.tien_hang + item.tien_hang_nt,
              tien_ck: acc.tien_ck + item.tien_ck_nt,
              doanh_thu: acc.doanh_thu + item.tien_nt,
            };
          },
          { thoi_gian: '', tien_hang: 0, tien_ck: 0, doanh_thu: 0 }
        );
        result.push(res);
      });

      result.push({
        thoi_gian: 'Tổng cộng',
        tien_hang: total.tien_hang,
        tien_ck: total.tien_ck,
        doanh_thu: total.tien,
      });
      return result;
    },
    columns: [
      {
        name: 'Thời gian',
        selector: (row) => row.thoi_gian,
        width: '150px',
      },
      {
        name: 'Tiền hàng',
        selector: (row) => row.tien_hang,
        format: (row) => numeralCustom(row.tien_hang).format(),
        center: true,
      },
      {
        name: 'Tiền chiết khấu',
        selector: (row) => row.tien_ck,
        format: (row) => numeralCustom(row.tien_ck).format(),
        center: TbRuler2Off,
      },
      {
        name: 'Doanh thu',
        selector: (row) => row.doanh_thu,
        format: (row) => numeralCustom(row.doanh_thu).format(),
        right: true,
      },
    ],
  },
};
timeConcerns.yesterday = cloneDeep(timeConcerns.today);
timeConcerns.lastweek = cloneDeep(timeConcerns.thisweek);
timeConcerns['7daysago'] = cloneDeep(timeConcerns.thisweek);
timeConcerns.lastquarter = cloneDeep(timeConcerns.thisquarter);
timeConcerns.lastyear = cloneDeep(timeConcerns.thisyear);

function FilterReportBanhang({
  setQueryObject = () => {},
  setConcern = () => {},
}) {
  const [valueConcern, setValueConcern] = useState(concerns[0].value);
  const [timeOption, setTimeOption] = useState();
  const [filter, setFilter] = useState({
    timeFrom: moment().startOf('months').format('YYYY-MM-DD'),
    timeTo: moment().format('YYYY-MM-DD'),
    kho: null,
  });

  useEffect(() => {
    const queryObject = {};
    if (filter.timeFrom) {
      queryObject.tu_ngay = filter.timeFrom;
    }
    if (filter.timeTo) {
      queryObject.den_ngay = filter.timeTo;
    }
    if (filter.kho) {
      queryObject.ma_kho = filter.kho.ma_kho;
    }
    setQueryObject(queryObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);
  useEffect(() => {
    if (valueConcern !== 'thoigian') {
      setConcern(concerns.find((item) => item.value === valueConcern));
    } else {
      let currentTimeConcern;
      if (timeOption?.value) {
        currentTimeConcern = timeConcerns[timeOption.value];
      } else {
        currentTimeConcern = timeConcerns.fromTo;
      }
      setConcern(currentTimeConcern);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueConcern, timeOption]);

  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 50px - 42px - 30px)',
        overflow: 'auto',
        p: 0.5,
      }}
      className="hidden-scroll"
    >
      <Stack gap={1}>
        <FilterRadios
          title="Mối quan tâm"
          values={concerns}
          defaultValue={valueConcern}
          onChange={(newConcern) => setValueConcern(newConcern)}
        />
        <FilterSelectApi
          title={LABEL_KHO}
          apiCode="dmkho"
          placeholder={`Chọn ${LABEL_KHO}`}
          selectedValue={filter.kho || null}
          value={
            filter.kho
              ? { ma_kho: filter.kho.ma_kho, ten_kho: filter.kho.ten_kho }
              : null
          }
          searchFileds={['ma_kho', 'ten_kho']}
          getOptionLabel={(option) => option.ten_kho}
          onSelect={(value) => setFilter({ ...filter, kho: value })}
        />
        <FilterTimeFromTo
          defaultTimeFrom={filter.timeFrom}
          defaultTimeTo={filter.timeTo}
          title="Thời gian"
          showOptions
          onSearch={(time) => setFilter({ ...filter, ...time })}
          onOptionChange={setTimeOption}
        />
      </Stack>
    </Box>
  );
}

export default FilterReportBanhang;
