import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { KEY_GIA_GOC } from '~/utils/constants';

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    hds: [],
    renderHds: [],
    indexHd: 0,
    currentHd: null,
    customer: null,
  },
  reducers: {
    createHd(state, action) {
      state.hds.push(action.payload);
    },
    updateHds(state, action) {
      state.hds = action.payload;
    },
    updateOneHd(state, action) {
      const { payload } = action;
      const newHd = cloneDeep(payload);
      const index = state.hds.findIndex((hd) => hd._id === newHd._id);
      if (index < 0) return;
      let tongTienHang = 0;
      let tongTienCk = 0;
      let tongThue = 0;
      let tienCkHd = 0;

      for (let i = 0; i < newHd.details.length; i++) {
        const detail = cloneDeep(newHd.details[i]);
        // tinh toan detail
        detail.gia_ban =
          (detail[KEY_GIA_GOC] || 0) -
          (detail.tien_ck || 0) / (detail.sl_xuat || 0);
        detail.tien = (detail?.gia_ban || 0) * (detail.sl_xuat || 0);
        detail.tien_thue = (detail.tien * (detail.thue_suat || 0)) / 100;
        detail.tien_xuat = detail.tien + detail.tien_thue;
        detail.tien_xuat_nt = detail.tien_xuat;
        newHd.details[i] = detail;
        // tinh tong cho hd2
        tongTienHang += detail.tien || 0;
        tongTienCk += detail.tien_ck || 0;
        tongThue += detail.tien_thue || 0;
      }
      if (
        tongTienHang !== (state.currentHd?.t_tien || 0) ||
        newHd.ma_kh !== state.currentHd?.ma_kh
      ) {
        newHd.tien_ck_hd = 0;
        newHd.ty_le_ck_hd = 0;
        newHd.exfields = {};
      }
      const chietkhaus = newHd?.exfields?.dmchietkhau || [];
      if (chietkhaus.length > 0) {
        tienCkHd = chietkhaus.reduce((acc, item) => {
          if ((item.ty_le_ck_hd || 0) > 0) {
            let tienCk = ((item.ty_le_ck_hd || 0) * tongTienHang) / 100;
            tienCk = tienCk > item.tien_ck ? item.tien_ck : tienCk;
            acc += tienCk;
            return acc;
          } else {
            acc += item.tien_ck || 0;
            return acc;
          }
        }, 0);
      } else {
        tienCkHd = ((newHd?.ty_le_ck_hd || 0) * tongTienHang) / 100;
      }
      newHd.tien_ck_hd = tienCkHd;
      tongTienCk += newHd.tien_ck_hd || 0;
      const tongThanhToan = tongTienHang - tongTienCk + tongThue;
      state.hds.splice(index, 1, {
        ...newHd,
        t_tien: tongTienHang,
        t_tien_nt: tongTienHang,
        tc_ck: tongTienCk,
        tc_ck_nt: tongTienCk,
        t_thue: tongThue,
        t_thue_nt: tongThue,
        t_tt: tongThanhToan,
        t_tt_nt: tongThanhToan,
      });
    },
    initRenderHds(state, action) {
      state.renderHds = action.payload;
    },
    updateCurrentHd(state, action) {
      state.currentHd = action.payload;
    },
    updateIndexHd(state, action) {
      state.indexHd = action.payload;
    },
    updateCustomer(state, action) {
      state.customer = action.payload;
    },
  },
});

export const {
  updateIndexHd,
  updateCurrentHd,
  updateHds,
  updateCustomer,
  updateOneHd,
  createHd,
  initRenderHds,
} = orderSlice.actions;
export default orderSlice.reducer;
