import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { v4 } from 'uuid';
import ButtonBase from '~/components/button/ButtonBase';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import ModalBase from '~/components/modal/ModalBase';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  formatDateDisplay,
  generateLinkImage,
  numeralCustom,
} from '~/utils/helpers';
import FormLo from '../../lo/FormLo';
import FormProduct from '../../product/FormProduct';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import { isArray } from 'lodash';
import SelectInput from '~/components/input/SelectInput';
import ProductImage from '~/assets/img/product.png';
import ChangeModeInput from '~/components/input/ChangeModeInput';
import SearchAndSelectInput from '~/components/input/SearchAndSelectInput';
import DvtsModal from '~/components/modal/DvtsModal';

const schema = yup.object({
  vat_tu: yup
    .object()
    .typeError('Vui lòng chọn hàng hóa')
    .required('Vui lòng chọn hàng hóa'),
  don_vi_tinh: yup
    .object()
    .typeError('Vui lòng chọn đơn vị tính')
    .required('Vui lòng chọn đơn vị tính'),
  sl_xuat: yup
    .number()
    .typeError('Số lượng tối thiểu là 1')
    .min(1, 'Số lượng tối thiểu là 1'),
});

function FormAddDetail({
  open,
  handleClose,
  isEdit,
  addDetail,
  defaultValues,
}) {
  const defaultData = {
    vat_tu: null,
    don_vi_tinh: null,
    lo: null,
    gia_ban_nt: 0,
    sl_xuat: 0,
    quy_cach: '',
    tien_ck_nt: 0,
    thue_suat: 0,
    tien_thue: 0,
    tien_thue_nt: 0,
    tien: 0,
    tien_nt: 0,
    tien_xuat: 0,
    tien_xuat_nt: 0,
    nh_hoa_hong: '',
    ten_nh_hoa_hong: '',
    picture: '',
    tk_dt: '1111',
    tk_gv: '1111',
    tk_ck: '1111',
    tk_vt: '1561',
  };
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        vat_tu: defaultValues.ma_vt
          ? {
              ma_vt: defaultValues.ma_vt,
              ten_vt: defaultValues.ten_vt,
              ma_dvt: defaultValues.ma_dvt,
              ma_lo_yn: !!defaultValues.ma_lo,
              gia_ban_le: defaultValues.gia_ban,
              thue_suat_nk: defaultValues.thue_suat,
              quy_cach: defaultValues.quy_cach,
              picture_thumb: defaultValues.picture,
            }
          : null,
        don_vi_tinh: defaultValues?.ma_dvt
          ? {
              ma_dvt: defaultValues?.ma_dvt || '',
              ten_dvt: defaultValues?.ten_dvt || '',
              gia_ban_nt: defaultValues?.gia_ban_nt,
            }
          : null,
        lo: defaultValues.ma_lo ? { ma_lo: defaultValues.ma_lo } : null,
      }
    : defaultData;
  const { asyncSearchList, asyncGetList } = useApisContext();
  const showAlert = useAlertContext();
  const [openDvts, setOpenDvts] = useState(false);
  const [dvts, setDvts] = useState([]);

  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: originalData,
  });
  const [los, setLos] = useState([]);

  const vatTu = watch('vat_tu');
  const donViTinh = watch('don_vi_tinh');
  const giaBan = watch('gia_ban_nt');
  const tyLeCk = watch('ty_le_ck');
  const tienCk = watch('tien_ck_nt');
  const soLuongXuat = watch('sl_xuat');
  const thueSuat = watch('thue_suat');

  const handleAddProductToDetails = ({
    product,
    gia_ban_le,
    ma_dvt,
    ten_dvt,
  }) => {
    setValue('vat_tu', { ...product, gia_ban_le, ma_dvt, ten_dvt });
    setValue('don_vi_tinh', { ma_dvt, ten_dvt });
    setValue('gia_ban_nt', gia_ban_le);
    setValue('sl_xuat', 1);
    setValue('quy_cach', product.quy_cach || 0);
    setValue('thue_suat', product.thue_suat_nk || 0);
    setValue('picture', product.picture_thumb || '');
    setValue('tien_nt', gia_ban_le * 1);
    setValue('nh_hoa_hong', product.nh_hoa_hong || '');
    setValue('ten_nh_hoa_hong', product.ten_nh_hoa_hong || '');
  };

  const handleSelectProduct = async (product) => {
    const { ma_vt, ma_dvt } = product;
    const respDvts = await asyncGetList({
      apiCode: 'dmqddvt',
      condition: {
        page: 1,
        limit: 99999,
        q: { ma_vt, ma_dvt: { $ne: ma_dvt } },
      },
    });
    if (isArray(respDvts) && respDvts.length > 0) {
      setDvts(() => {
        return respDvts.reduce(
          (acc, item) => {
            return [
              ...acc,
              {
                ...product,
                ma_dvt: item.ma_dvt,
                ten_dvt: item.ten_dvt,
                gia_ban_le: item.gia_ban_nt,
              },
            ];
          },
          [{ ...product }]
        );
      });
      setOpenDvts(true);
    } else {
      handleAddProductToDetails({
        product,
        gia_ban_le: product?.gia_ban_le || 0,
        ma_dvt: product?.ma_dvt || 0,
        ten_dvt: product?.ten_dvt || 0,
      });
    }
  };

  const getLos = async () => {
    try {
      const data = await asyncSearchList({
        apiCode: 'dmlo',
        condition: {
          page: 1,
          limit: 999999,
          q: { status: true, ma_vt: vatTu?.ma_vt },
        },
      });
      if (data && Array.isArray(data)) {
        setLos(data);
      }
    } catch (error) {
      showAlert(error?.message || 'Lỗi khi lấy thông tin lô');
    }
  };

  const generateDataPost = (values) => {
    const { vat_tu, don_vi_tinh, lo, sl_xuat, ...fields } = values;
    const tongTien = (sl_xuat || 0) * (fields.gia_ban_nt || 0);
    const tienThue =
      (((tongTien || 0) - (fields?.tien_ck_nt || 0)) *
        (fields.thue_suat || 0)) /
      100;
    const tienXuat = tongTien - (fields.tien_ck_nt || 0) + tienThue;
    return {
      ...fields,
      gia_ban: fields.gia_ban_nt || 0,
      ma_vt: vat_tu?.ma_vt || '',
      ten_vt: vat_tu?.ten_vt || '',
      ma_dvt: don_vi_tinh?.ma_dvt || '',
      ten_dvt: don_vi_tinh?.ten_dvt || '',
      ma_lo: lo?.ma_lo || '',
      han_sd: lo?.han_sd || '',
      sl_xuat,
      tien: tongTien || 0,
      tien_nt: tongTien || 0,
      tien_thue: tienThue,
      tien_thue_nt: tienThue,
      tien_xuat: tienXuat,
      tien_xuat_nt: tienXuat,
    };
  };

  const handleSave = (values) => {
    return new Promise((resovle) => {
      setTimeout(() => {
        addDetail(generateDataPost(values), isEdit);
        handleClose();
        resovle();
      }, 200);
    });
  };

  useEffect(() => {
    if (vatTu?.ma_lo_yn) {
      getLos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vatTu]);

  return (
    <>
      <DvtsModal
        open={openDvts}
        onClose={() => setOpenDvts(false)}
        dvts={dvts}
        handleAddProductToDetails={handleAddProductToDetails}
      />
      <ModalBase
        open={open}
        handleClose={handleClose}
        width="500px"
        title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} Chi tiết`}
        actions={[
          <ButtonBase
            key={v4()}
            onClick={handleSubmit(handleSave)}
            loading={isSubmitting}
            startIcon={<FiSave style={{ fontSize: '16px' }} />}
          >
            Lưu
          </ButtonBase>,
          <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
            Hủy
          </ButtonBase>,
        ]}
      >
        <Box
          sx={{
            width: '100%',
            padding: '10px',
            maxHeight: 'calc(90vh - 20px - 39px - 39px)',
            overflow: 'auto',
          }}
        >
          <Grid container spacing={2}>
            {/* vat tu */}
            <Grid item xs={12}>
              <Box
                sx={{
                  pb: 2,
                  borderBottom: '1px dashed',
                  borderColor: 'divider',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: '10px',
                    px: 1,
                    py: 0.5,
                  }}
                >
                  <SearchAndSelectInput
                    onSelect={handleSelectProduct}
                    listBoxSx={{
                      maxHeight: '60vh',
                      '& .MuiAutocomplete-option': { fontSize: '12px' },
                    }}
                  />
                </Box>
                {!!vatTu && (
                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    gap={1}
                    sx={{
                      px: 2,
                      py: 1,
                      borderRadius: '10px',
                      backgroundColor: 'primary.fif',
                    }}
                  >
                    <Avatar
                      src={
                        vatTu?.picture_thumb
                          ? generateLinkImage(vatTu?.picture_thumb)
                          : ProductImage
                      }
                      sx={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '2px',
                        border: '1px dashed',
                        borderColor: 'primary.main',
                      }}
                    />
                    <Typography sx={{ flex: 1 }}>
                      {vatTu?.ten_vt} ({vatTu?.ma_vt})
                      {vatTu.exfields?.hoa_don && (
                        <Chip
                          component="span"
                          size="small"
                          variant="filled"
                          color="warning"
                          label={`Hóa đơn${
                            vatTu.thue_suat_nk > 0
                              ? ` ${vatTu.thue_suat_nk}%`
                              : ''
                          }`}
                          sx={{ '& .MuiChip-label': { color: 'common.white' } }}
                        />
                      )}
                    </Typography>
                    <Chip
                      component="span"
                      size="small"
                      variant="filled"
                      color="secondary"
                      label={donViTinh?.ten_dvt || donViTinh?.ma_dvt}
                      sx={{ '& .MuiChip-label': { color: 'common.white' } }}
                    />
                  </Stack>
                )}
              </Box>
            </Grid>
            {/* gia ban */}
            <Grid item xs={12}>
              <Controller
                name="gia_ban_nt"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    labelWidth="30%"
                    readOnly
                    label="Giá bán"
                    value={numeralCustom(value).format()}
                    onChange={(e) => {
                      onChange(numeralCustom(e.target.value).value());
                    }}
                  />
                )}
              />
            </Grid>
            {/* Chiet khau */}
            {/* <Grid item xs={12}>
              <ChangeModeInput
                labelWidth="30%"
                label="Giảm giá / SP"
                textFieldSx={{
                  '& .MuiInputBase-input': {
                    fontSize: '14px',
                    textAlign: 'right',
                    padding: '0px',
                  },
                }}
                tienGoc={giaBan}
                defaultTyLeCK={tyLeCk || 0}
                defaultTienCk={(tienCk || 0) / (soLuongXuat || 1)}
                onChietKhauChange={({ ty_le_ck, tien_ck }) => {
                  setValue('ty_le_ck', ty_le_ck);
                  setValue('tien_ck_nt', tien_ck * (soLuongXuat || 1));
                }}
              />
            </Grid> */}
            {/* Đơn giá */}
            {/* <Grid item xs={12}>
              <TextInput
                readOnly
                labelWidth="30%"
                label="Đơn giá"
                value={numeralCustom(
                  (giaBan || 0) - (tienCk || 0) / (soLuongXuat || 1)
                ).format()}
                onChange={() => {}}
              />
            </Grid> */}
            {/* so luong */}
            <Grid item xs={12}>
              <Controller
                name="sl_xuat"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    required
                    type="number"
                    labelWidth="30%"
                    label="Số lượng"
                    value={value}
                    onChange={(e) => {
                      const val = e.target.value;
                      const newTienHang = giaBan * val;
                      const newTienThue =
                        (((newTienHang || 0) - (tienCk || 0)) * thueSuat) / 100;
                      const newTienXuat = newTienHang - newTienThue;
                      onChange(val);
                      setValue('tien_nt', newTienHang);
                      setValue('tien_thue_nt', newTienThue);
                      setValue('tien_xuat_mt', newTienXuat);
                    }}
                    errorMessage={errors?.sl_xuat?.message}
                  />
                )}
              />
            </Grid>
            {/* thue */}
            <Grid item xs={12}>
              <Controller
                name="thue_suat"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    labelWidth="30%"
                    label="Thuế VAT"
                    InputProps={{ endAdornment: '%' }}
                    value={numeralCustom(value).format()}
                    onChange={(e) => {
                      // luu gia tri
                      onChange(numeralCustom(e.target.value).value());
                    }}
                  />
                )}
              />
            </Grid>
            {/* quy cach */}
            <Grid item xs={12}>
              <TextInput
                readOnly
                labelWidth="30%"
                label="Quy cách"
                name="quy_cach"
                register={register}
                placeholder="Tự động theo hàng hóa"
              />
            </Grid>
            {/* lo */}
            {vatTu?.ma_lo_yn && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="lo"
                  render={({ field: { value, onChange } }) => (
                    <SelectApiInput
                      disabled={!vatTu}
                      label="Lô - HSD"
                      labelWidth="30%"
                      apiCode="tontucthoi"
                      placeholder="Chọn lô hàng (nếu có)"
                      searchFileds={['ma_lo']}
                      condition={!!vatTu ? { ma_vt: vatTu?.ma_vt } : {}}
                      getOptionLabel={(option) => {
                        const currentLo = los.find(
                          (item) => item.ma_lo === option.ma_lo
                        );
                        return option.ma_lo
                          ? `${option.ma_lo} - ${formatDateDisplay(
                              currentLo?.han_sd,
                              'DD/MM/YYYY'
                            )} ${option.ton00 ? ` - Tồn: ${option.ton00}` : ''}`
                          : '';
                      }}
                      selectedValue={value}
                      value={value || { ma_lo: '', ton00: '' }}
                      onSelect={onChange}
                      FormAdd={FormLo}
                      errorMessage={errors?.lo?.message}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider sx={{ borderStyle: 'dashed' }} />
            </Grid>

            {/* tien hang */}
            <Grid item xs={12}>
              <Controller
                name="tien_nt"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    labelWidth="30%"
                    readOnly
                    label="Tiền hàng"
                    value={numeralCustom(value).format()}
                    onChange={(e) => {
                      onChange(numeralCustom(e.target.value).value());
                    }}
                  />
                )}
              />
            </Grid>
            {/* tien ck */}
            <Grid item xs={12}>
              <Controller
                name="tien_ck_nt"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    labelWidth="30%"
                    label="Giảm giá"
                    value={numeralCustom(value).format()}
                    onChange={(e) => {
                      onChange(numeralCustom(e.target.value).value());
                    }}
                  />
                )}
              />
            </Grid>
            {/* tien hang */}
            {/* <Grid item xs={12} md={6}>
            <Controller
              name="tien_nt"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  readOnly
                  labelWidth="30%"
                  label="Tiền hàng"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    // luu gia tri
                    onChange(numeralCustom(e.target.value).value());
                  }}
                />
              )}
            />
          </Grid> */}
            {/* tien xuat */}
            {/* <Grid item xs={12} md={6}>
            <Controller
              name="tien_xuat_nt"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  readOnly
                  labelWidth="30%"
                  label="Thành tiền"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    // luu gia tri
                    onChange(numeralCustom(e.target.value).value());
                  }}
                />
              )}
            />
          </Grid> */}
          </Grid>
        </Box>
      </ModalBase>
    </>
  );
}

export default FormAddDetail;
