import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { v4 } from 'uuid';
import ButtonBase from '~/components/button/ButtonBase';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import ModalBase from '~/components/modal/ModalBase';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatDateDisplay, numeralCustom } from '~/utils/helpers';
import FormProduct from '../../product/FormProduct';
import FormDVT from '../../dvt/FormDVT';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import FormLo from '../../lo/FormLo';

const originSchema = {
  vat_tu: yup
    .object()
    .typeError('Vui lòng chọn hàng hóa')
    .required('Vui lòng chọn hàng hóa'),
  sl_ton_ss: yup.string().required('Vui lòng nhập số lượng tồn sổ sách'),
  sl_ton_tt: yup.string().required('Vui lòng nhập số lượng tồn thực tế'),
};

function FormAddDetail({
  open,
  handleClose,
  isEdit,
  addDetail,
  defaultValues,
}) {
  const [schema, setSchema] = useState(yup.object(originSchema));
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          vat_tu: defaultValues.ma_vt
            ? {
                ma_vt: defaultValues.ma_vt,
                ten_vt: defaultValues.ten_vt,
                ma_dvt: defaultValues.ma_dvt,
                ten_dvt: defaultValues.ten_dvt,
                ma_lo_yn: !!defaultValues.ma_lo,
              }
            : null,
          don_vi_tinh: defaultValues.ma_dvt
            ? { ma_dvt: defaultValues.ma_dvt }
            : null,
          lo: defaultValues.ma_lo
            ? { ma_lo: defaultValues.ma_lo, ten_lo: defaultValues.ten_lo }
            : null,
        }
      : {
          tien_phi_nt: 0,
          tien_ck_nt: 0,
        },
  });
  const vatTu = watch('vat_tu');
  const slTonSoSach = watch('sl_ton_ss');
  const slTonThucTe = watch('sl_ton_tt');

  const { asyncSearchList } = useApisContext();
  const showAlert = useAlertContext();

  const getLos = async () => {
    try {
      const data = await asyncSearchList({
        apiCode: 'dmlo',
        condition: {
          page: 1,
          limit: 999999,
          q: { status: true, ma_vt: vatTu?.ma_vt },
        },
      });
      if (data && Array.isArray(data) && data.length === 1) {
        setValue('lo', data[0]);
      }
    } catch (error) {
      showAlert(error?.message || 'Something went wrong!');
    }
  };
  const handleSave = (values) => {
    return new Promise((resovle) => {
      setTimeout(() => {
        addDetail(values, isEdit);
        handleClose();
        resovle();
      }, 200);
    });
  };

  useEffect(() => {
    if (vatTu) {
      setValue('sl_ton_ss', vatTu?.ton00 || 0);
      if (vatTu.ma_lo_yn) {
        getLos();
        setSchema(
          yup.object({
            ...originSchema,
            lo: yup
              .object()
              .typeError('Vui lòng chọn lô')
              .required('Vui lòng chọn lô'),
          })
        );
      } else {
        setSchema(yup.object(originSchema));
      }
    }
    setValue(
      'don_vi_tinh',
      !!vatTu ? { ma_dvt: vatTu.ma_dvt, ten_dvt: vatTu.ten_dvt } : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vatTu]);

  useEffect(() => {
    setValue('sl_xuat', (slTonSoSach || 0) - (slTonThucTe || 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slTonSoSach, slTonThucTe]);

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="800px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} kiểm`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="vat_tu"
            render={({ field: { value, onChange } }) => (
              <SelectApiInput
                labelWidth="30%"
                disabled={isEdit}
                label="Hàng hóa"
                required
                apiCode="dmvt"
                placeholder="Chọn hàng hóa"
                searchFileds={['ma_vt', 'ten_vt']}
                getOptionLabel={(option) => option.ten_vt}
                selectedValue={value}
                value={value || { ma_vt: '', ten_vt: '' }}
                onSelect={onChange}
                FormAdd={FormProduct}
                errorMessage={errors?.vat_tu?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="don_vi_tinh"
            render={({ field: { value, onChange } }) => (
              <SelectApiInput
                labelWidth="30%"
                label="Đơn vị tính"
                readOnly
                apiCode="dmdvt"
                placeholder="Đơn vị tính"
                searchFileds={['ma_dvt', 'ten_dvt']}
                getOptionLabel={(option) => option.ma_dvt}
                selectedValue={value}
                value={value || { ma_dvt: '' }}
                onSelect={onChange}
                FormAdd={FormDVT}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="sl_ton_ss"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                readOnly
                labelWidth="30%"
                required
                label="Tồn sổ sách"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  onChange(numeralCustom(e.target.value).value());
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="sl_ton_tt"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                required
                labelWidth="30%"
                label="Tồn thực tế"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  onChange(numeralCustom(e.target.value).value());
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="sl_xuat"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                disabled
                labelWidth="30%"
                label="Chênh lệch"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  onChange(numeralCustom(e.target.value).value());
                }}
              />
            )}
          />
        </Grid>
        {!!vatTu && vatTu.ma_lo_yn && (
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="lo"
              render={({ field: { value, onChange } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="Lô - HSD"
                  required
                  apiCode="dmlo"
                  placeholder="Chọn lô"
                  searchFileds={['ma_lo', 'ten_lo']}
                  condition={{ ma_vt: vatTu.ma_vt, status: true }}
                  getOptionLabel={(option) =>
                    `${option.ma_lo}${
                      option.han_sd
                        ? ` - ${formatDateDisplay(option.han_sd)}`
                        : ''
                    }`
                  }
                  selectedValue={value}
                  value={value || { ma_lo: '', ten_lo: '' }}
                  onSelect={onChange}
                  FormAdd={FormLo}
                  errorMessage={errors?.lo?.message}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </ModalBase>
  );
}

export default FormAddDetail;
